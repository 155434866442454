import React from "react";

import { Box, Text, Link, VStack } from "@chakra-ui/react";

import firebase from "firebase/app";
import "firebase/functions";
import { FirebaseAuthConsumer } from "@react-firebase/auth";

import AccountForm from "../components/AccountForm";

import { Link as RouterLink } from "react-router-dom";

export default function Signup() {
  const userSignUp = function (credentials) {
    return new Promise((resolve, reject) => {
      const signUpFn = firebase.functions().httpsCallable("api/handleSignUp");
      const errorTitle = "Could not sign up";
      signUpFn(credentials)
        .then((result) => {
          console.log(result.data);
          if (result.data.error) {
            reject({
              title: result.data.title ?? errorTitle,
              description:
                result.data.description ??
                "(an internal server error occurred)",
            });
          } else {
            const actionCodeSettings = {
              url:
                window.location.origin +
                `/?email=${encodeURIComponent(
                  credentials.email
                )}#/verifyAccount`,
              handleCodeInApp: true,
            };

            return firebase
              .auth()
              .sendSignInLinkToEmail(credentials.email, actionCodeSettings)
              .then(() => {
                resolve(result.data);
              });
          }
        })
        .catch((error) => {
          console.warn(error);
          reject({
            title: errorTitle,
            description: error.message,
          });
        });
    });
  };

  return (
    <Box>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user }) => {
          if (isSignedIn) {
            return (
              <Text>
                You are already signed in. Visit your dashboard{" "}
                <Link as={RouterLink} to="/dashboard" color="teal.500">
                  here
                </Link>
                .
              </Text>
            );
          } else {
            return (
              <VStack spacing={8}>
                <AccountForm
                  title="Create an account"
                  sublineText={"Already have an account?"}
                  linkText={"Sign in here."}
                  linkHref={"/login"}
                  onSubmit={userSignUp}
                  submitText="Sign Up"
                  withName={true}
                  withPassword={false}
                  isRequired={true}
                />
              </VStack>
            );
          }
        }}
      </FirebaseAuthConsumer>
    </Box>
  );
}
