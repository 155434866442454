import React, { useState, useEffect } from "react";
import { Box, Text, Button, Spinner, Heading } from "@chakra-ui/react";

import RemarkGFM from "remark-gfm";
import RemarkMath from "remark-math";
import RemarkBreaks from "remark-breaks";
import ReactMarkdown from "react-markdown";

import Tex from "@matejmazur/react-katex";
import "katex/dist/katex.min.css";

import LaTeX from "./LaTeX";

const Markdown = ({ markdown, latexUtils, parseJson = true }) => {
  const renderers = {
    heading: ({ node }) => {
      const depths = [null, "xl", "lg", "md", "sm", "sm", "sm"];
      return (
        <Heading size={depths[node.depth] as string | undefined}>
          {node.children[0].value}
        </Heading>
      );
    },
    inlineMath: ({ value }) => <Tex math={value} />,
    math: ({ value }) => {
      value = value.trim();
      if (value.indexOf("!tex") === 0) {
        value = value.slice(4);
        let texProps = {};
        if (value.indexOf("{") === 0 && value.indexOf("}") !== -1) {
          texProps = JSON.parse(value.slice(0, value.indexOf("}") + 1));
          value = value.slice(value.indexOf("}") + 1);
        }
        return (
          <LaTeX input={value} latexUtils={latexUtils} renderProps={texProps} />
        );
      }
      return <Tex settings={{ display: true }} block math={value} />;
    },
    text: ({ node, value }) => {
      return <Text as="span">{value}</Text>;
    },
    break: (node) => {
      return <br />;
    },
  };

  if (parseJson) {
    markdown = JSON.parse(`"${markdown}"`);
  }

  return (
    <ReactMarkdown
      plugins={[RemarkBreaks, RemarkGFM, RemarkMath]}
      renderers={renderers}
      allowDangerousHtml={true}
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default React.memo(Markdown);
