import React, { useState, useEffect } from "react";
import { Box, Text, Button, Spinner, VStack, Heading } from "@chakra-ui/react";
import "../App.css";

import { dvi2html } from "dvi2html";
import { Writable } from "stream";
import { Buffer as JSBuffer } from "buffer/";

const LaTeX = ({ input, latexUtils, renderProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [output, setOutput] = useState("");
  const [machine, setMachine] = useState(null as any);

  const postprocessHtml = (html) => {
    html = html.replaceAll("&#173;", "Ω");
    return html;
  };

  const renderLatex = async () => {
    const dvi = await latexUtils.tex(input);

    // console.log(btoa(String.fromCharCode(...dvi)));

    let html = "";
    const page = new Writable({
      write(chunk, encoding, callback) {
        html = html + chunk.toString();
        callback();
      },
    });

    async function* streamBuffer() {
      yield Buffer.from(dvi);
      return;
    }

    const machine = dvi2html(new JSBuffer(dvi), page);
    html = postprocessHtml(html);
    // console.log(machine);

    setOutput(html);
    setMachine(machine);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(renderLatex, 1000);
    }
  }, [isLoading]);

  let usingOutput = output;

  if (usingOutput) {
    // eslint-disable-next-line quotes
    usingOutput = usingOutput.replace('class="page"', 'class="latex-page"');
  }

  const heightPx = (renderProps.height ?? 200).toString() + "px";

  return (
    <Box
      height={
        isLoading
          ? heightPx
          : (renderProps.height ?? machine!.paperheight / 4).toString() + "px"
      }
    >
      {isLoading ? (
        <VStack mt={4}>
          <Spinner color="red.300" size="md" thickness="4px" />
          <Text color="gray.200" fontSize="15px">
            Rendering figure...
          </Text>
        </VStack>
      ) : (
        <Box
          style={{ transform: `scale(${renderProps.scale ?? 1.0})` }}
          height={heightPx}
        >
          <Box
            display="flex"
            width={"100%"}
            height={heightPx}
            marginTop={(renderProps.offset ?? 0).toString() + "px"}
            alignItems="center"
            justifyContent="center"
            style={{
              ["--paperwidth" as any]:
                (machine.paperwidth + (renderProps.hoffset ?? 0)).toString() +
                "px",
            }}
            dangerouslySetInnerHTML={{ __html: usingOutput }}
          ></Box>
        </Box>
      )}
    </Box>
  );
};

export default LaTeX;
