import React from "react";
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  Button,
  Stack,
  SimpleGrid,
  Image,
  Icon,
  IconProps,
  IconButton,
  createIcon,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import {
  FcDoughnutChart,
  FcMultipleDevices,
  FcPrivacy,
  FcTimeline,
  FcNext,
} from "react-icons/fc";
import { CompanyName } from "../components/Logo";

interface FeatureProps {
  title: string;
  children: string;
  icon: React.ReactElement;
}

const Feature = (props: FeatureProps) => {
  const { title, children, icon } = props;
  return (
    <Stack spacing="6" direction={{ base: "column", md: "row" }}>
      <Box fontSize="6xl">{icon}</Box>
      <Stack spacing="1">
        <Text fontWeight="extrabold" fontSize="lg">
          {title}
        </Text>
        <Box color={mode("gray.600", "gray.400")}>{children}</Box>
      </Stack>
    </Stack>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

function FeaturesHero() {
  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text as={"span"} color={"primary.400"}>
              Coding challenges,
            </Text>
            <br />
            <Text
              as={"span"}
              position={"relative"}
              zIndex={1}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "primary.400",
                zIndex: -1,
              }}
            >
              hardware style!
            </Text>
          </Heading>
          <Text color={mode("gray.500", "gray.400")}>
            Why spend your engineer&apos;s time on interviewing candidates who
            will not progress in your recruiting pipeline? <CompanyName />{" "}
            enables you to automatically screen hardware applicants at scale.
          </Text>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Blob
            w={"150%"}
            h={"150%"}
            position={"absolute"}
            top={"-20%"}
            left={0}
            zIndex={0}
            color={mode("primary.50", "primary.400")}
          />
          <Box
            position={"relative"}
            height={"300px"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={
                "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80"
              }
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

function Features() {
  return (
    <>
      <FeaturesHero />
      <Box as="section" py="24">
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="10" spacingY="14">
            <Feature title="Secure by default" icon={<FcPrivacy />}>
              At vero eos et accusam et justo duo dolores et ea rebum. Stet
              clita kasd gubergren, no sea takimata sanctus.
            </Feature>
            <Feature title="Always up to date" icon={<FcTimeline />}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore.
            </Feature>
            <Feature title="Incredible statistics" icon={<FcDoughnutChart />}>
              At vero eos et accusam et justo duo dolores et ea rebum. Stet
              clita kasd gubergren, no sea takimata sanctus.
            </Feature>
            <Feature
              title="Support for multiple devices"
              icon={<FcMultipleDevices />}
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore.
            </Feature>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
}

export default Features;
