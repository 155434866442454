import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Input,
  Heading,
  HStack,
  Flex,
  Spacer,
  Stack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import Markdown from "../Markdown";

import {
  ArrowForwardIcon,
  ArrowBackIcon,
  CheckCircleIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { FaUndo } from "react-icons/fa";

import { useHistory } from "react-router-dom";

import "../../App.css";

const INPUT_BOX_TYPES = ["text", "number"];

const InputElement = (props) => {
  if (INPUT_BOX_TYPES.includes(props.type)) {
    const onlyWant = [
      "autoComplete",
      "defaultValue",
      "placeholder",
      "onChange",
    ];
    const filteredProps = {} as any;
    for (const key of onlyWant) {
      filteredProps[key] = props[key];
    }
    return <Input type={props.type} {...filteredProps} />;
  } else if (props.type === "radio") {
    const [value, setValue] = useState(
      props.defaultValue.length ? props.defaultValue : undefined
    );
    const valueChangeHandler = (v) => {
      setValue(v as string);
      props.onChange({
        target: {
          value: v as string,
        },
      });
    };
    return (
      <RadioGroup onChange={valueChangeHandler} value={value}>
        <Stack direction="row">
          {props.choices.map((v, i) => (
            <Radio value={v} key={"radio" + (props.keyPrefix ?? "") + i}>
              {v}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    );
  } else {
    return <>(no input type provided)</>;
  }
};

const QNA = ({
  part,
  params,
  initialState,
  saveStateHandler,
  nextPartHandler,
  previousPartHandler,
  queryPartHandler,
  latexUtils,
  isPreview,
}) => {
  const [workState, setWorkState] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [movingNext, setMovingNext] = useState(false);
  const [movingBack, setMovingBack] = useState(false);
  const [movingGrade, setMovingGrade] = useState(false);

  const history = useHistory();

  const handleSave = async (e) => {
    await setSaving(true);
    await saveStateHandler(workState);
    await setSaving(false);
  };

  const handleNext = async (e) => {
    await setMovingNext(true);
    await saveStateHandler(workState);
    await nextPartHandler();
  };

  const handleBack = async (e) => {
    await setMovingBack(true);
    await saveStateHandler(workState);
    await previousPartHandler();
  };

  const handleReturn = async (e) => {
    await handleSave(e);
    history.push(`/interviews/session/${params.interview_id}`);
  };

  const handleGrade = async (e) => {
    await setMovingGrade(true);
    await handleSave(e);
    await saveStateHandler("grade", workState);
    await setMovingGrade(false);
  };

  const toShow = queryPartHandler();
  const num_answers =
    part.num_answers ??
    (Array.isArray(part.interface.input) ? part.interface.input.length : 1);

  const arrayRange = (num) => (num >= 0 ? Array.from(Array(num).keys()) : []);
  const fitToLength = (arr: Array<any>, num) =>
    arr.concat(arrayRange(num - arr.length).map((_) => "")).slice(0, num);

  const answerConfigs = arrayRange(num_answers).map((idx) => {
    const output = {} as any;
    for (const key in part.interface) {
      const val = part.interface[key];

      if (key.startsWith("choices")) {
        // Keys corresponding to arrays of primitives
        if (idx == 0 && (key == "choices" || key == "choices0")) {
          output["choices"] = val;
        } else if ("choices" + idx.toString() == key) {
          output["choices"] = val;
        }
        continue;
      }

      if (!Array.isArray(val) || idx >= val.length) {
        if (idx == 0 && !Array.isArray(val)) {
          output[key] = val;
        } else {
          output[key] = key == "input" ? "text" : null;
        }
        continue;
      }

      if (["type"].includes(key)) {
        // Skipped keys for batching
        continue;
      } else {
        output[key] = val[idx];
      }
    }
    return output;
  });

  return (
    <Flex direction="column" height="100%">
      <Box mt={2}>
        <Markdown markdown={part.statement} latexUtils={latexUtils} />
      </Box>
      <Spacer />
      <Box>
        <FormControl mt={4}>
          {answerConfigs.map((config, idx) => (
            <Box
              key={"answerbox" + idx.toString()}
              mb={idx + 1 < answerConfigs.length ? 2 : 0}
            >
              {config.label && <FormLabel>{config.label}</FormLabel>}
              <InputGroup>
                {config.prefix && INPUT_BOX_TYPES.includes(config.input) && (
                  <InputLeftAddon>
                    <Markdown
                      markdown={config.prefix}
                      parseJson={false}
                      latexUtils={latexUtils}
                    />
                  </InputLeftAddon>
                )}
                <InputElement
                  autoComplete="off"
                  type={config.input}
                  onChange={(evt) => {
                    console.log(
                      idx,
                      workState.answer ?? arrayRange(idx).map((_) => "")
                    );
                    setWorkState({
                      ...workState,
                      answer: [
                        ...fitToLength(workState.answer ?? [], idx),
                        evt.target.value,
                        ...(workState.answer ?? []).slice(idx + 1),
                      ],
                    });
                  }}
                  defaultValue={
                    Array.isArray(workState.answer) &&
                    idx < workState.answer.length
                      ? workState.answer[idx] ?? ""
                      : ""
                  }
                  placeholder={config.placeholder ?? "Your answer"}
                  keyPrefix={idx.toString()}
                  choices={config.choices ?? []}
                />
                {config.suffix && INPUT_BOX_TYPES.includes(config.input) && (
                  <InputRightAddon>
                    <Markdown
                      markdown={config.suffix}
                      parseJson={false}
                      latexUtils={latexUtils}
                    />
                  </InputRightAddon>
                )}
              </InputGroup>
              {config.helper_text && (
                <FormHelperText>{config.helper_text}</FormHelperText>
              )}
            </Box>
          ))}
        </FormControl>
        <Flex mt={4}>
          <HStack spacing={2}>
            <Button
              variant="outline"
              colorScheme={"teal"}
              isLoading={saving}
              loadingText="Saving"
              onClick={handleSave}
            >
              Save
            </Button>
            {toShow.hasBack && (
              <Button
                variant="outline"
                colorScheme={"blue"}
                isLoading={movingBack}
                loadingText="Submitting"
                onClick={handleBack}
                leftIcon={<ArrowBackIcon />}
              >
                Previous
              </Button>
            )}
            {toShow.hasNext && (
              <Button
                variant="outline"
                colorScheme={"blue"}
                isLoading={movingNext}
                loadingText="Submitting"
                onClick={handleNext}
                rightIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            )}
            {isPreview && (
              <Button
                variant="outline"
                colorScheme={"brown"}
                isLoading={movingGrade}
                loadingText="Grading"
                onClick={handleGrade}
                rightIcon={<TimeIcon />}
              >
                Autograde
              </Button>
            )}
          </HStack>
          <Spacer />
          <Button
            variant="outline"
            colorScheme={toShow.hasNext ? "red" : "green"}
            leftIcon={toShow.hasNext ? <FaUndo /> : <CheckCircleIcon />}
            onClick={handleReturn}
          >
            {toShow.hasNext ? "Question List" : "Finish"}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default QNA;
