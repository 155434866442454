import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Spinner,
  SimpleGrid,
  ScaleFade,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import logo from "../logo.svg";
import "../App.css";

import { AddIcon, EditIcon } from "@chakra-ui/icons";

import { Link as RouterLink } from "react-router-dom";

import Table from "../components/Table";
import IfSignedIn from "../components/IfSignedIn";

import firebase from "firebase/app";
import "firebase/functions";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { CompanyName } from "../components/Logo";
dayjs.extend(relativeTime);

const DarkHero = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [isLoading, setIsLoading] = useState(2 as number);
  const [tableData, setTableData] = useState([[], [], {}] as any);
  const [dialogBody, setDialogBody] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [dialogLink, setDialogLink] = useState("");
  const toast = useToast();

  const getUserDashboard = async () => {
    if (isLoading != 2) return;
    setIsLoading(1);
    const idToken = await firebase.auth().currentUser!.getIdToken(true);
    const getUserDashboard = firebase
      .functions()
      .httpsCallable("api/getUserDashboard");

    try {
      const dashboardData = (await getUserDashboard({ idToken })).data;
      dashboardData[1] = dashboardData[1].map((row: any, rowIdx: number) => {
        return row.map((col: any, colIdx: number) => {
          if (typeof col === "object") {
            if (col.type === "link") {
              const cautionAction = (e) => {
                e.preventDefault();
                setDialogBody(col.cautionText);
                setDialogButton(col.text);
                setDialogLink(col.href);
                setIsOpen(true);
              };

              return (
                <RouterLink
                  key={
                    "dashboardItem" +
                    rowIdx.toString() +
                    "_" +
                    colIdx.toString()
                  }
                  to={col.href}
                >
                  <Button
                    colorScheme={col.colorScheme ?? "telegram"}
                    variant={col.variant ?? "solid"}
                    leftIcon={
                      col.annotation === "edit" ? <EditIcon /> : undefined
                    }
                    onClick={col.cautionText ? cautionAction : undefined}
                  >
                    {col.text}
                  </Button>
                </RouterLink>
              );
            } else if (col.type === "timestamp") {
              return (
                (col.prefix ?? "") + dayjs(col.value._seconds * 1000).fromNow()
              );
            } else {
              return col.value;
            }
          } else {
            return col;
          }
        });
      });
      setTableData(dashboardData);
    } catch (error) {
      toast({
        title: "Could not retrieve dashboard",
        description: (error as any).message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setIsLoading(0);
  };

  useEffect(() => {
    getUserDashboard();
  }, [isLoading]);

  const isRegularUser = tableData[2].isRegularUser ?? true;
  const cancelRef = React.useRef() as React.RefObject<any>;

  return (
    <header className="App-header">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure?
            </AlertDialogHeader>

            <AlertDialogBody>{dialogBody}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <RouterLink to={dialogLink}>
                <Button colorScheme="red" onClick={onClose} ml={3}>
                  {dialogButton}
                </Button>
              </RouterLink>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box>
        <Text
          bgGradient="linear(to-l, #7928CA,#FF0080)"
          bgClip="text"
          fontSize="5xl"
          fontWeight="extrabold"
        >
          Welcome to <CompanyName />
        </Text>
      </Box>
      {!isRegularUser && <Divider my={4} />}
      <SimpleGrid
        columns={isRegularUser ? 1 : 2}
        spacing={10}
        textAlign="center"
      >
        <Box fontSize="0.75em">
          {!isRegularUser && <Heading mb={2}>Interviews</Heading>}
          <Table
            isLoading={isLoading > 0}
            loadSpinner={true}
            loadingText={"Loading dashboard..."}
            header={tableData[0]}
            data={tableData[1]}
          />
        </Box>
        {!isRegularUser && (
          <Box>
            <Heading mb={2}>Actions</Heading>
            <Divider mb={4} />
            <VStack>
              <RouterLink to={"/interviews/new"} style={{ width: "100%" }}>
                <Button
                  isFullWidth={true}
                  leftIcon={<AddIcon />}
                  colorScheme="teal"
                >
                  Create Interview
                </Button>
              </RouterLink>
            </VStack>
          </Box>
        )}
      </SimpleGrid>
    </header>
  );
};

export default function Dashboard() {
  return (
    <Box className="AppDashboard">
      <IfSignedIn component={() => <DarkHero />} />
    </Box>
  );
}
