import React, { useState } from "react";

import {
  Flex,
  Box,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Skeleton,
  Stack,
  VStack,
  Text,
  useToast,
  useStyles,
} from "@chakra-ui/react";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const TableComponent = ({
  header,
  data,
  style = "simple",
  size = "md",
  colorScheme = "gray",
  isLoading = false,
  caption = null,
  loadSpinner = false,
  loadingText = "",
}) => {
  const renderRow = (row, idx = 0, isHeader = false) => {
    const Cell = (isHeader ? Th : Td) as React.ElementType;
    return (
      <Tr key={idx.toString() + "-head"}>
        {row.map((item, cellIdx) => (
          <Cell key={idx.toString() + "-" + cellIdx.toString()}>{item}</Cell>
        ))}
      </Tr>
    );
  };

  if (loadSpinner && isLoading) {
    return (
      <VStack>
        <Box mt={4}>
          <Spinner color="red.500" size="lg" thickness="4px" />
        </Box>
        {loadingText.length > 0 && <Text fontSize="14pt">{loadingText}</Text>}
      </VStack>
    );
  }

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Table
        variant={isLoading ? "unstyled" : style}
        colorScheme={colorScheme}
        size={size}
      >
        {caption && <TableCaption>{caption}</TableCaption>}
        {header.length > 0 && <Thead>{renderRow(header, 0, true)}</Thead>}
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={header.length}>
                <Stack>
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                </Stack>
              </Td>
            </Tr>
          ) : (
            data.length > 0 && data.map((row, idx) => renderRow(row, idx + 1))
          )}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default TableComponent;
