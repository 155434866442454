import React, { useEffect } from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { Home } from "./pages/Home";
import SignupPage from "./pages/Signup";
import LoginPage from "./pages/Signin";
import Logout from "./pages/Signout";
import VerifyAccount from "./pages/VerifyAccount";
import Dashboard from "./pages/Dashboard";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Interview from "./pages/Interview";
import EditCreateInterview from "./pages/EditCreateInterview";
import Question from "./pages/Question";
import ManageInterviewees from "./pages/ManageInterviewees";
import AdminPanel from "./pages/AdminPanel";

import Navbar from "./components/Navbar";
import { Footer } from "./components/Footer";

import {
  Flex,
  Spacer,
  Box,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import firebase from "firebase/app";
import "firebase/analytics";

function FirebaseAnalyticsInitializer() {
  const [initializedAnalytics, setInitializedAnalytics] = React.useState(false);
  if (!initializedAnalytics) {
    firebase.analytics();
    setInitializedAnalytics(true);
  }
  return <></>;
}

function App() {
  return (
    <Box bg={useColorModeValue("gray.50", "inherit")}>
      <Box minH="100vh">
        <Router basename="/">
          <FirebaseAnalyticsInitializer />
          <Flex
            direction="column"
            align="center"
            maxW={{ xl: "1200px" }}
            m="0 auto"
          >
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/admin" component={AdminPanel} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/logout" component={Logout} />
              <Route path="/verifyAccount" component={VerifyAccount} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/features" component={Features} />
              <Route
                path="/interviews/session/:session_id"
                component={Interview}
              />
              <Route
                key="editInterview"
                path="/interviews/edit/:interview_id"
                component={EditCreateInterview}
              />
              <Route
                key="newInterview"
                path="/interviews/new"
                component={EditCreateInterview}
              />
              <Route
                path="/interviews/send/:interview_id"
                component={ManageInterviewees}
              />
              <Route
                path="/question/:question_id/:variant_id/:interview_id?"
                component={Question}
              />
            </Switch>
          </Flex>
        </Router>
      </Box>
      <Box minH="8vh">
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
