/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  HStack,
  VStack,
  Tag,
  Divider,
  Spinner,
  ScaleFade,
  Center,
  useToast,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";
import "../App.css";

import { Link as RouterLink } from "react-router-dom";

import Table from "../components/Table";
import IfSignedIn from "../components/IfSignedIn";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const parseQuestion = (question, qid, isPreview) => {
  const variantId = isPreview
    ? question.variant
    : question.variants.findIndex(
        (variant, vid) => Object.keys(variant).length > 0
      );
  const title: string = isPreview
    ? question.title
    : question.variants[variantId].title;
  const questionId = isPreview ? question.question : qid;
  const tags = isPreview ? question.tags : question.variants[variantId].tags;
  return { variantId, title, questionId, tags };
};

const generateTableData = (interviewId, questions, responses, isPreview) => {
  const tableHeader = ["Question Name", "Categories", "Start", "Status"];
  const toTitleCase = (s) => s.charAt(0).toUpperCase() + s.slice(1);
  const tableData = questions.map((question, qid) => {
    const { variantId, title, questionId, tags } = parseQuestion(
      question,
      qid,
      isPreview
    );
    const currentResponses = qid in responses ? responses[qid] : {};
    let totalParts = 0,
      totalCompleted = 0;
    for (const [groupKey, group] of Object.entries(currentResponses)) {
      for (const [partKey, part] of Object.entries(group as any)) {
        totalParts += 1;
        if (part !== null) {
          totalCompleted += 1;
        }
      }
    }

    let statusDescription: string;
    if (totalCompleted == totalParts) {
      statusDescription = "Complete";
    } else if (totalCompleted > 0) {
      statusDescription = "Incomplete";
    } else {
      statusDescription = "Not started";
    }

    return [
      title,
      <HStack key={"qtags" + qid} spacing={1}>
        {tags.map((tag, i) => (
          <Tag size="md" colorScheme="teal" key={"category" + i}>
            {toTitleCase(tag)}
          </Tag>
        ))}
      </HStack>,
      <RouterLink
        to={`/question/${questionId}/${variantId}/${interviewId}`}
        key={"qstart" + qid}
      >
        <Button
          colorScheme="blue"
          variant="outline"
          rightIcon={<ArrowForwardIcon />}
        >
          Start
        </Button>
      </RouterLink>,
      <Text key={"qprogress" + qid}>{statusDescription}</Text>,
    ];
  });
  return [tableHeader, tableData];
};

const InterviewList = ({ params }) => {
  const [isLoading, setIsLoading] = useState(2 as number);
  const [interview, setInterview] = useState({} as any);
  const [isPreview, setIsPreview] = useState({} as any);
  const tableData = generateTableData(
    params.session_id,
    interview.questions ?? [],
    interview.responses ?? {},
    isPreview
  );
  const toast = useToast();

  const fetchInterview = async () => {
    if (isLoading == 0 && !isPreview && !interview.started) {
      const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await timeout(500);
      setIsLoading(-1);
      return;
    }

    if (isLoading != 2) return;
    setIsLoading(1);

    const idToken = await firebase.auth().currentUser!.getIdToken(true);
    const fetchInterviewFn = firebase
      .functions()
      .httpsCallable("api/startOrFetchInterview");
    try {
      const result = await fetchInterviewFn({
        idToken,
        session_id: params.session_id,
      });

      const { interview, isPreview } = result.data;
      setIsPreview(isPreview ?? false);
      setInterview(interview ?? {});

      if (!interview) {
        throw "No interview found";
      }
    } catch (e) {
      toast({
        title: "Could not load interview.",
        description: "We were unable to retrieve the given interview for you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setIsLoading(0);
  };

  useEffect(() => {
    fetchInterview();
  }, [isLoading]);

  const SpinnerWithCaption = ({ caption }) => {
    return (
      <VStack>
        <Box mt={4}>
          <Spinner color="red.500" size="lg" thickness="4px" />
        </Box>
        <Text>{caption}</Text>
      </VStack>
    );
  };

  if (isLoading > 0) {
    return <SpinnerWithCaption caption="Loading interview" />;
  }

  if (!isPreview && !interview.started && isLoading == 0) {
    return <SpinnerWithCaption caption="Setting up interview session" />;
  }

  return (
    <ScaleFade in={true} initialScale={0.9}>
      <Box textAlign="center">
        <Box fontSize="1.5em">
          <Text
            bgGradient="linear(to-l, #7928CA,#FF0080)"
            bgClip="text"
            fontSize="5xl"
            fontWeight="extrabold"
          >
            {isLoading > 0
              ? "Interview Mode"
              : interview.name ?? interview.interviewName}
          </Text>
        </Box>
        <Box textAlign="center" fontSize="1.05em" marginTop="1em" mb={4}>
          <Table
            header={tableData[0]}
            data={tableData[1]}
            colorScheme="purple"
            style="striped"
            isLoading={isLoading > 0}
          />
        </Box>
        <Center>
          <Box width="50%">
            <Text fontSize="lg">
              You have <b>{interview.duration ?? 0}</b> minutes to complete this
              interview.
            </Text>
            <Divider my={6} />
            <Button
              colorScheme="green"
              variant="outline"
              rightIcon={<CheckCircleIcon />}
            >
              Submit
            </Button>
          </Box>
        </Center>
      </Box>
    </ScaleFade>
  );
};

export default function Interview(props) {
  return (
    <IfSignedIn
      component={() => <InterviewList params={props.match.params} />}
    />
  );
}
