import React, { useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  Spinner,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon as ChakraMenuIcon,
  MenuCommand,
  MenuDivider,
  useColorModeValue,
  useToken,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/firestore";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { CompanyName, Logo } from "./Logo";
import { AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import { BiSun } from "react-icons/bi";
import { HiOutlineMoon } from "react-icons/hi";

const MenuItem = ({
  children,
  isLast = false,
  to = "/",
  noLink = false,
  ...rest
}) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      fontWeight={500}
      display="inline-block"
      borderBottom="2px solid transparent"
      transition="0.3s"
      _hover={{ borderBottom: "2px solid currentColor" }}
      {...rest}
    >
      {noLink ? (
        <>{children}</>
      ) : (
        <Link to={to} onClick={(evt) => evt.target.blur()}>
          {children}
        </Link>
      )}
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Navbar = (props) => {
  const [show, setShow] = useState(false);
  const [userRecord, setUserRecord] = useState(null as any);
  const toggleMenu = () => setShow(!show);
  const colorPrefix = useColorModeValue("primary", "pink");

  const fetchUserRecord = async (user) => {
    if (userRecord) return;
    const record = await firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get();
    setUserRecord(record.data());
  };

  const ColorModeChanger = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
      <MenuItem _hover={{}} noLink mr={2}>
        <IconButton
          aria-label="Toggle color mode"
          icon={colorMode === "light" ? <HiOutlineMoon /> : <BiSun />}
          rounded="md"
          size="sm"
          variant="ghost"
          onClick={toggleColorMode}
        />
      </MenuItem>
    );
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={[
        `${colorPrefix}.500`,
        `${colorPrefix}.500`,
        "transparent",
        "transparent",
      ]}
      color={["white", "white", `${colorPrefix}.700`, `${colorPrefix}.700`]}
      {...props}
    >
      <Flex align="center">
        <Heading as="h1" size="lg" letterSpacing={"-.1rem"}>
          <Link to="/">
            <HStack>
              <Logo color={useToken("colors", `${colorPrefix}.700`)} />
              <Text>
                <CompanyName />
              </Text>
            </HStack>
          </Link>
        </Heading>
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box>

      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              if (providerId === null) {
                return <Spinner color={`${colorPrefix}.500`} />;
              }
              if (isSignedIn && !userRecord) {
                fetchUserRecord(user);
                return <Spinner color={`${colorPrefix}.500`} />;
              }
              return (
                <>
                  {!isSignedIn && (
                    <>
                      <MenuItem to="/">Home</MenuItem>
                      <MenuItem to="/features">Features</MenuItem>
                      <MenuItem to="/pricing">Pricing</MenuItem>
                    </>
                  )}
                  <ColorModeChanger />
                  <MenuItem
                    _hover={{}}
                    to={isSignedIn ? "/dashboard" : "/signup"}
                    as="p"
                    isLast
                  >
                    <Button
                      size="sm"
                      rounded="md"
                      color={[
                        `${colorPrefix}.500`,
                        `${colorPrefix}.500`,
                        "white",
                        "white",
                      ]}
                      bg={[
                        "white",
                        "white",
                        `${colorPrefix}.500`,
                        `${colorPrefix}.500`,
                      ]}
                      _hover={{
                        bg: [
                          `${colorPrefix}.100`,
                          `${colorPrefix}.100`,
                          `${colorPrefix}.600`,
                          `${colorPrefix}.600`,
                        ],
                      }}
                      leftIcon={isSignedIn ? <AiOutlineHome /> : undefined}
                    >
                      {isSignedIn ? "Dashboard" : "Get Started"}
                    </Button>
                  </MenuItem>

                  {isSignedIn && (
                    <>
                      <MenuItem
                        marginLeft="1rem"
                        as="span"
                        _hover={{}}
                        noLink
                        isLast
                      >
                        <Menu autoSelect={false}>
                          <MenuButton>
                            <Avatar size="sm" name={userRecord.name} />
                          </MenuButton>
                          <MenuList
                            bg={[
                              `${colorPrefix}.500`,
                              `${colorPrefix}.500`,
                              `${colorPrefix}.500`,
                              `${colorPrefix}.500`,
                            ]}
                          >
                            <Link to={"/logout"}>
                              <ChakraMenuItem
                                textColor={"white"}
                                _hover={{
                                  bg: [
                                    `${colorPrefix}.300`,
                                    `${colorPrefix}.300`,
                                    `${colorPrefix}.400`,
                                    `${colorPrefix}.400`,
                                  ],
                                }}
                                icon={<AiOutlineLogout />}
                              >
                                Sign Out
                              </ChakraMenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                        {/* <Avatar size="sm" name={userRecord.name} /> */}
                      </MenuItem>
                    </>
                  )}
                </>
              );
            }}
          </FirebaseAuthConsumer>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Navbar;
