import React, { useState } from "react";
import {
  Text,
  Spinner,
  VStack,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";

import firebase from "firebase/app";
import "firebase/auth";

import CenteredAlert from "../components/CenteredAlert";

export default function Logout() {
  const [error, setError] = useState(null as any);
  firebase
    .auth()
    .signOut()
    .then(() => {
      setError(false);
    })
    .catch((err) => {
      setError(err);
    });
  if (error === null) {
    return (
      <VStack>
        <Text>Signing you out...</Text>
        <Spinner color="primary.500" />
      </VStack>
    );
  } else if (error === false) {
    return (
      <CenteredAlert
        title="Signed out!"
        description="You have been signed out."
        status="success"
        height="200px"
      />
    );
    return <Text>You have been logged out.</Text>;
  } else {
    return <Text>An error occurred when trying to sign you out: {error}</Text>;
  }
}
