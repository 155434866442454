import React, { useState, useEffect, createRef } from "react";
import {
  Box,
  Text,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  ButtonGroup,
  Spinner,
  HStack,
  Stack,
  Tag,
  TagLabel,
  TagCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Wrap,
  WrapItem,
  Divider,
  Tooltip,
  SimpleGrid,
  useToast,
  ScaleFade,
} from "@chakra-ui/react";
import {
  ArrowForwardIcon,
  InfoOutlineIcon,
  RepeatIcon,
  CheckIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { FiSend } from "react-icons/fi";
import "../App.css";

import { Link as RouterLink } from "react-router-dom";

import Table from "../components/Table";
import IfSignedIn from "../components/IfSignedIn";
import { Link } from "../components/Utilities";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const InterviewList = ({ params }) => {
  const isEdit = !!params.interview_id;
  const [isLoading, setIsLoading] = useState(2 as number);
  const [interview, setInterview] = useState({} as any);
  const [requestOutput, setRequestOutput] = useState({} as any);
  const form = createRef() as React.RefObject<HTMLFormElement>;
  const toast = useToast();

  const fetchInterview = async () => {
    if (isLoading != 2 || !isEdit) return;
    setIsLoading(1);
    const questionsRef = firebase
      .firestore()
      .collection("interviews")
      .doc(params.interview_id);
    const snapshot = await questionsRef.get();
    if (!snapshot.exists) {
      toast({
        title: "Could not find interview.",
        description: "We were unable to retrieve the given interview for you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      setInterview(snapshot.data()!);
    }

    setIsLoading(0);
  };

  useEffect(() => {
    fetchInterview();
  }, [isLoading]);

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    let lastButton = null;
    for (const item of [...event.target.elements]) {
      if (item.nodeName.toLowerCase() === "button") {
        lastButton = item.dataset.submitType;
      }
    }

    if (!lastButton) {
      toast({
        title: "Could not create interview.",
        description: "Unable to detect which button you clicked.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const timeTarget = parseInt(event.target.elements.timeTarget.value),
      maxQuestions = parseInt(event.target.elements.maxQuestions.value),
      interviewName = event.target.elements.interviewName.value,
      interviewDuration = parseInt(
        event.target.elements.interviewDuration.value
      );

    const allowList = JSON.parse(
        event.target.elements.allowList.dataset["items"]
      ),
      denyList = JSON.parse(event.target.elements.denyList.dataset["items"]);

    if (lastButton === "create") {
      await generateInterview({
        timeTarget,
        maxQuestions,
        interviewName,
        interviewDuration,
        allowList,
        denyList,
      });
    }
  };

  const generateInterview = async ({
    interviewName,
    interviewDuration,
    timeTarget,
    maxQuestions = 5,
    allowList = [],
    denyList = [],
  }) => {
    setRequestOutput({ loading: true });
    const idToken = await firebase.auth().currentUser!.getIdToken(true);
    const generateInterviewFn = firebase
      .functions()
      .httpsCallable("api/generateCodingInterview");

    try {
      const result = await generateInterviewFn({
        idToken,
        allowList,
        denyList,
        timeTarget,
        maxQuestions,
        duration: interviewDuration,
        name: interviewName,
      });
      console.log(result.data);
      setRequestOutput({
        status: "success",
        title: "Generated coding interview",
        description: (
          <Text>
            Generated interview of estimated duration{" "}
            {result.data.estimated_time} minutes (
            {result.data.match_threshold * 100}% match to time target). See the
            created interview
            <Link to={"/interviews/edit/" + result.data.interview_id}>
              here
            </Link>
            .
          </Text>
        ),
      });
      return true;
    } catch (error) {
      console.warn(error);
      setRequestOutput({
        status: "error",
        title: "Could not generate coding interview",
        description: (error as any).message,
      });
      return false;
    }
  };

  const generateTableData = (interview_id, questions) => {
    const tableHeader = ["Question Name", "Categories", "View"];
    const tableData = questions.map((question, qid) => [
      question.title,
      <Box key={"qtags" + qid}>
        <WrappedTags
          tags={question.tags}
          color="teal"
          size="md"
          spacing={2}
          width="250px"
        />
      </Box>,
      <RouterLink
        to={`/question/${question.question}/${question.variant}/${interview_id}`}
        key={"qstart" + qid}
      >
        <Button colorScheme="blue" variant="outline" leftIcon={<ViewIcon />}>
          View
        </Button>
      </RouterLink>,
    ]);
    return [tableHeader, tableData];
  };

  const tableData = generateTableData(
    params.interview_id,
    interview.questions ?? []
  );

  function SliderInput({ min = 0, max = 100, step = 1, initial = 30 }) {
    const [value, setValue] = React.useState(initial);
    const handleChange = (value) => setValue(value);

    return (
      <Flex>
        <NumberInput
          maxW="100px"
          mr="2rem"
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Slider
          flex="1"
          focusThumbOnChange={false}
          value={value}
          onChange={handleChange}
          defaultValue={initial}
          min={min}
          max={max}
          step={step}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb
            fontSize="sm"
            boxSize="38px"
            children={value.toString() + "m"}
          />
        </Slider>
      </Flex>
    );
  }

  function InfoTooltip({ message }) {
    return (
      <Tooltip label={message} placement="right">
        <InfoOutlineIcon w={5} h={5} mt={-1} />
      </Tooltip>
    );
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      if (txt.toUpperCase() === txt) return txt;
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function WrappedTags({
    tags,
    color,
    size = "lg",
    spacing = 3,
    width = "400px",
    onClose = undefined,
  }) {
    const onCloseFn = onClose ?? ((tag: any) => tag);
    return (
      <Wrap spacing={spacing} mb={3} width={width}>
        {tags.map((tag) => (
          <WrapItem key={"tag" + tag}>
            <Tag
              size={size}
              borderRadius="full"
              variant="solid"
              colorScheme={color}
            >
              <TagLabel>{toTitleCase(tag)}</TagLabel>
              {onClose && <TagCloseButton onClick={onCloseFn} />}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    );
  }

  function EditableTagCloud({
    color = "green",
    allowedTags = [],
    initialValue = [],
  }) {
    const [tags, setTags] = useState(initialValue as any);
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        const tag = event.target.value;
        if (!tags.includes(tag)) {
          setTags([...tags, tag]);
        }
        event.target.value = "";
      }
    };

    return (
      <>
        <WrappedTags
          tags={tags}
          width="500px"
          spacing={3}
          onClose={
            ((tag) => {
              setTags(tags.filter((item) => item !== tag));
            }) as any
          }
          color={color}
        />
        <Input
          type="text"
          placeholder="Type a category and hit enter"
          onKeyDown={handleKeyDown}
          data-items={JSON.stringify(tags)}
        />
      </>
    );
  }

  const MainGrid = (props) => {
    return (
      <SimpleGrid columns={isEdit ? 2 : 1} spacing={10}>
        <form ref={form} onSubmit={onSubmit} onKeyDown={checkKeyDown}>
          <Box>
            <Heading size="md">
              Interview Settings{" "}
              <InfoTooltip
                message={
                  "These settings directly affect how the interview is shown to and executed by the candidates."
                }
              />
            </Heading>
            <Stack spacing={2}>
              <FormControl id="interviewName" isRequired>
                <FormLabel>Interview Name</FormLabel>
                <Input
                  type="text"
                  defaultValue={interview.name}
                  autoComplete="off"
                />
                <FormHelperText>
                  The interview title shown to candidates.
                </FormHelperText>
              </FormControl>
              <FormControl id="interviewDuration" isRequired>
                <FormLabel>Interview Duration</FormLabel>
                <SliderInput
                  min={5}
                  step={5}
                  max={240}
                  initial={interview.duration ?? 60}
                />
                <FormHelperText>
                  The time limit to take this interview, in minutes.
                </FormHelperText>
              </FormControl>
            </Stack>

            <Divider my={4} />
            <Heading size="md">
              Question Constraints{" "}
              <InfoTooltip
                message={
                  "These constraints are used to automatically choose questions for your interview."
                }
              />
            </Heading>
            <Stack spacing={3} mt={1}>
              <FormControl id="maxQuestions" isRequired>
                <FormLabel>Max Questions</FormLabel>
                <NumberInput
                  min={1}
                  max={5}
                  defaultValue={interview.maxQuestions ?? "2"}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText>
                  The maximum number of questions permitted in the interview
                  (max 5).
                </FormHelperText>
              </FormControl>
              <FormControl id="timeTarget" isRequired>
                <FormLabel>Time Target</FormLabel>
                <SliderInput
                  min={5}
                  step={5}
                  max={240}
                  initial={interview.timeTarget ?? 30}
                />
                <FormHelperText>
                  An estimate of how long the interview should take a reasonable
                  candidate, in minutes.
                </FormHelperText>
              </FormControl>
              <FormControl id="allowList">
                <FormLabel>Question Category Allowlist</FormLabel>
                <EditableTagCloud
                  color="green"
                  initialValue={interview.allowList ?? []}
                />
                <FormHelperText>
                  Only allow interview questions of the given categories (empty
                  means no constraints).
                </FormHelperText>
              </FormControl>
              <FormControl id="denyList">
                <FormLabel>Question Category Denylist</FormLabel>
                <EditableTagCloud
                  color="red"
                  initialValue={interview.denyList ?? []}
                />
                <FormHelperText>
                  Disallow interview questions of the given categories (empty
                  means no constraints).
                </FormHelperText>
              </FormControl>
            </Stack>

            <Divider my={4} />

            {isEdit ? (
              <ButtonGroup mt={2} variant="outline" spacing={4}>
                <Button
                  colorScheme="blue"
                  leftIcon={<CheckIcon />}
                  type="submit"
                  data-submit-type={"save"}
                >
                  Save Settings
                </Button>
                <Button
                  colorScheme="pink"
                  leftIcon={<RepeatIcon />}
                  type="submit"
                  data-submit-type={"regenerate"}
                >
                  Regenerate Questions
                </Button>
              </ButtonGroup>
            ) : (
              <Button
                colorScheme="blue"
                variant="outline"
                isLoading={requestOutput.loading}
                loadingText="Creating..."
                type="submit"
                data-submit-type={"create"}
              >
                Create Interview!
              </Button>
            )}
          </Box>
        </form>

        {isEdit && (
          <Box>
            <Heading fontSize="1.5em">Generated Interview</Heading>
            <Text mt={2} size="sm">
              These questions were automatically chosen based on your
              constraints.
            </Text>
            <Box textAlign="center" fontSize="0.90em" marginTop="1em">
              <Table
                header={tableData[0]}
                data={tableData[1]}
                colorScheme="purple"
                style="striped"
                isLoading={isLoading > 0}
              />
            </Box>

            <Divider my={4} />
            <Heading fontSize="1.5em">Interview Actions</Heading>
            <RouterLink to={"/interviews/send/" + params.interview_id}>
              <Button
                mt={4}
                variant="outline"
                colorScheme="blue"
                leftIcon={<FiSend />}
                isFullWidth={true}
              >
                Manage Recipients
              </Button>
            </RouterLink>
            <RouterLink to={"/interviews/session/" + params.interview_id}>
              <Button
                mt={4}
                variant="outline"
                colorScheme="purple"
                leftIcon={<ViewIcon />}
                isFullWidth={true}
              >
                Preview Interview
              </Button>
            </RouterLink>
          </Box>
        )}
      </SimpleGrid>
    );
  };

  return (
    <Box textAlign="center" mb={4}>
      <Box fontSize="1.5em">
        <Text
          bgGradient="linear(to-l, #7928CA,#FF0080)"
          bgClip="text"
          fontSize="5xl"
          fontWeight="extrabold"
        >
          {isEdit ? "Edit Interview" : "New Interview"}
        </Text>
      </Box>
      {Object.keys(requestOutput).length > 0 && !requestOutput.loading && (
        <Alert
          status={requestOutput["status"]}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          mb={4}
          rounded="2px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {requestOutput["title"]}
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {requestOutput["description"]}
          </AlertDescription>
        </Alert>
      )}
      <Divider my={4} />

      {isEdit && isLoading > 0 ? (
        <Spinner color="red.500" size="lg" thickness="4px" />
      ) : (
        <MainGrid />
      )}
    </Box>
  );
};

export default function Interview(props) {
  return (
    <IfSignedIn
      component={() => (
        <ScaleFade initialScale={0.9} in={true}>
          <InterviewList params={props.match.params} />
        </ScaleFade>
      )}
    />
  );
}
