import React from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertStatus,
  Spinner,
  Fade,
} from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

import firebase from "firebase/app";
import { FirebaseAuthConsumer } from "@react-firebase/auth";

import CenteredAlert from "./CenteredAlert";

export default function IfSignedIn({ component, passUser = false }) {
  return (
    <FirebaseAuthConsumer>
      {({ isSignedIn, user, providerId }) => {
        if (providerId === null) {
          return <Spinner color="red.500" size="lg" thickness="4px" />;
        }
        if (isSignedIn) {
          if (user.emailVerified) {
            return (
              <Fade in={true}>{passUser ? component(user) : component()}</Fade>
            );
          } else {
            return (
              <CenteredAlert
                title="Email not verified"
                description={
                  <>
                    Your account email has not been verified yet. Check your
                    spam folder in case the email was lost, or contact us for
                    help{" "}
                    <Link as={RouterLink} to="/contact_us" color="teal.500">
                      here
                    </Link>
                    .
                  </>
                }
              />
            );
          }
        } else {
          return (
            <CenteredAlert
              title="Not signed in"
              description={
                <Text>
                  You are not signed in. Login{" "}
                  <Link as={RouterLink} to="/login" color="teal.500">
                    here
                  </Link>
                  .
                </Text>
              }
              height="225px"
              width="300px"
            />
          );
        }
      }}
    </FirebaseAuthConsumer>
  );
}
