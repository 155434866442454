import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  HStack,
  Divider,
  Grid,
  GridItem,
  useToast,
  Heading,
  Avatar,
  Link,
  ScaleFade,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  EditIcon,
  AddIcon,
  EmailIcon,
  DeleteIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { AiOutlineUpload } from "react-icons/ai";
import "../App.css";

import { Link as RouterLink } from "react-router-dom";

import Table from "../components/Table";
import IfSignedIn from "../components/IfSignedIn";
import { pluralize } from "../utils/helpers";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const generateTableData = (interview_id, recipients) => {
  const tableHeader = ["Recipient", "Email", "Status", "Delete"];
  const detectStatus = (recipient) => {
    if (!recipient.sent || !recipient.sessionId) {
      return "Interview not sent";
    }

    if (!recipient.taken) {
      return "Interview not taken";
    } else {
      return "Interview completed";
    }
  };

  const tableData = recipients.map((recipient, rid) => [
    <HStack key={"rname" + rid}>
      <Avatar name={recipient.name} />
      <Text>{recipient.name}</Text>
    </HStack>,
    <Link
      color="teal.500"
      href={"mailto:" + recipient.email}
      key={"remail" + rid}
    >
      {recipient.email}
    </Link>,
    <Text key={"rstatus" + rid}>{detectStatus(recipient)}</Text>,
    <Button
      key={"rdelete" + rid}
      colorScheme="red"
      variant="outline"
      leftIcon={<DeleteIcon />}
    >
      Delete
    </Button>,
  ]);

  return [tableHeader, tableData];
};

const IntervieweeManager = ({ params }) => {
  const [isLoading, setIsLoading] = useState(2 as number);
  const [interview, setInterview] = useState({} as any);
  const tableData = generateTableData(
    params.interview_id,
    interview.recipients ?? []
  );
  const toast = useToast();

  const refreshRecipients = async () => {
    const questionsRef = firebase
      .firestore()
      .collection("interviews")
      .doc(params.interview_id);
    const snapshot = await questionsRef.get();
    if (!snapshot.exists) {
      toast({
        title: "Could not retrieve recipients.",
        description:
          "We were unable to retrieve recipients for the current interview.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      const interviewData = snapshot.data()!;
      const recipientsSnapshot = await questionsRef
        .collection("recipients")
        .get();

      interviewData.recipients = recipientsSnapshot.docs.map((doc: any) =>
        doc.data()
      );

      setInterview(interviewData);
    }
  };

  const fetchRecipients = async () => {
    if (isLoading != 2) return;
    setIsLoading(1);
    await refreshRecipients();
    setIsLoading(0);
  };

  useEffect(() => {
    fetchRecipients();
  }, [isLoading]);

  const AddRecipientField = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [addRecipientIsLoading, setAddRecipientIsLoading] = useState(false);
    const initialRef =
      React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const addRecipient = async () => {
      setAddRecipientIsLoading(true);
      const idToken = await firebase.auth().currentUser!.getIdToken(true);
      const addRecipientFn = firebase
        .functions()
        .httpsCallable("api/updateInterviewRecipients");
      let success = false;

      try {
        if (!name.length || !email.length) {
          throw new Error("Cannot have empty input");
        }

        const result = await addRecipientFn({
          idToken,
          action: "add",
          interviewId: params.interview_id,
          data: {
            name,
            email,
          },
        });

        console.log(result.data);
        success = true;
        toast({
          status: "success",
          title: "Added recipient",
          description: <Text>Added {name} to recipients.</Text>,
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        console.warn(error);
        toast({
          status: "error",
          title: "Could not add recipient",
          description: (error as any).message,
          duration: 9000,
          isClosable: true,
        });
      }

      setAddRecipientIsLoading(false);

      if (success) {
        onClose();
        setTimeout(() => {
          setIsLoading(2); // refresh recipients
        }, 200);
      }
    };

    return (
      <>
        <Button
          mt={4}
          variant="outline"
          colorScheme="green"
          leftIcon={<AddIcon />}
          isFullWidth={true}
          onClick={onOpen}
        >
          Add Recipient
        </Button>

        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          onClose={onClose}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add a recipient</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder="Lionel Messi"
                  onChange={(evt) => setName(evt.target.value)}
                  autoComplete="off"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="lionel@messi.com"
                  type="email"
                  onChange={(evt) => setEmail(evt.target.value)}
                  autoComplete="off"
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={addRecipient}
                isLoading={addRecipientIsLoading}
                loadingText="Adding"
              >
                Add
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const SendInterviewField = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [sendInterviewIsLoading, setSendInterviewIsLoading] = useState(false);
    const cancelRef =
      React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const onClose = () => setIsOpen(false);

    const sendInterview = async () => {
      setSendInterviewIsLoading(true);
      const idToken = await firebase.auth().currentUser!.getIdToken(true);
      const sendInterviewFn = firebase
        .functions()
        .httpsCallable("api/sendOutInterview");
      let success = false;

      try {
        const result = await sendInterviewFn({
          idToken,
          interviewId: params.interview_id,
        });

        console.log(result.data);
        const { numProcessed, numSent } = result.data;
        success = true;
        toast({
          status: "success",
          title: "Sent interview",
          description: (
            <Text>
              Sent out {numSent} new {pluralize("interview", numSent)} to{" "}
              {numProcessed} {pluralize("recipient", numProcessed)}.
            </Text>
          ),
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        console.warn(error);
        toast({
          status: "error",
          title: "Could not send interview",
          description: (error as any).message,
          duration: 9000,
          isClosable: true,
        });
      }

      setSendInterviewIsLoading(false);
      setIsOpen(false);

      if (success) {
        setTimeout(() => {
          setIsLoading(2); // refresh recipients' status
        }, 200);
      }
    };

    return (
      <>
        <Button
          mt={4}
          variant="outline"
          colorScheme="red"
          leftIcon={<EmailIcon />}
          isFullWidth={true}
          onClick={() => setIsOpen(true)}
        >
          Send Interview
        </Button>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          size="lg"
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Send Interview
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text>
                  This will send out interviews to all recipients who have not
                  already received one.
                </Text>
                <Text mt={2}>
                  Are you sure? You cannot undo this action afterwards.
                </Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={sendInterview}
                  isLoading={sendInterviewIsLoading}
                  ml={3}
                >
                  Send
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };

  return (
    <Box textAlign="center">
      <Box fontSize="1.5em">
        <Text
          bgGradient="linear(to-l, #7928CA,#FF0080)"
          bgClip="text"
          fontSize="5xl"
          fontWeight="extrabold"
        >
          Manage Recipients
        </Text>
      </Box>

      <Divider my={4} />

      <Grid gap={8} h="70%" templateColumns="repeat(3, 1fr)">
        <GridItem>
          <Heading fontSize="1.5em">Actions</Heading>
          <AddRecipientField />
          <Button
            mt={4}
            variant="outline"
            colorScheme="teal"
            leftIcon={<AiOutlineUpload />}
            isFullWidth={true}
          >
            Import Recipients
          </Button>
          <SendInterviewField />
          <RouterLink to={"/interviews/edit/" + params.interview_id}>
            <Button
              mt={4}
              variant="outline"
              colorScheme="blue"
              leftIcon={<EditIcon />}
              isFullWidth={true}
            >
              Edit Interview
            </Button>
          </RouterLink>
          <RouterLink to={"/interviews/session/" + params.interview_id}>
            <Button
              mt={4}
              variant="outline"
              colorScheme="purple"
              leftIcon={<ViewIcon />}
              isFullWidth={true}
            >
              Preview Interview
            </Button>
          </RouterLink>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading fontSize="1.5em">Current Recipients</Heading>
          <Box textAlign="center" fontSize="1.05em">
            {isLoading || tableData[1].length > 0 ? (
              <Table
                header={tableData[0]}
                data={tableData[1]}
                colorScheme="purple"
                style="striped"
                isLoading={isLoading > 0}
              />
            ) : (
              <Text mt={4}>No recipients added yet.</Text>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default function ManageInterviewees(props) {
  return (
    <IfSignedIn
      component={() => (
        <ScaleFade initialScale={0.9} in={true}>
          <IntervieweeManager params={props.match.params} />
        </ScaleFade>
      )}
    />
  );
}
