import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  HStack,
  VStack,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertStatus,
  Spinner,
} from "@chakra-ui/react";
import logo from "../logo.svg";
import "../App.css";

import Table from "../components/Table";
import IfSignedIn from "../components/IfSignedIn";
import { Link } from "../components/Utilities";

import firebase from "firebase/app";
import "firebase/functions";
import { FirebaseAuthConsumer } from "@react-firebase/auth";

const AdminControls = ({ user }) => {
  const [isLoading, setIsLoading] = useState(2 as number);
  const [isAdmin, setIsAdmin] = useState(false);
  const [output, setOutput] = useState({} as any);

  const checkUserRing = async () => {
    if (isLoading != 2) return;
    setIsLoading(1);
    const userRef = firebase.firestore().collection("users").doc(user.uid);
    const snapshot = await userRef.get();

    if (snapshot.exists && snapshot.data()!.isAdmin) {
      setIsAdmin(true);
    }

    setIsLoading(0);
  };

  useEffect(() => {
    checkUserRing();
  }, [isLoading]);

  const generateInterview = async () => {
    setOutput({ loading: true });
    const idToken = await firebase.auth().currentUser!.getIdToken(true);
    const generateInterviewFn = firebase
      .functions()
      .httpsCallable("api/generateCodingInterview");
    const allowList = [],
      timeTarget = 20,
      maxQuestions = 5;
    const duration = 80,
      name = "Test Interview";
    generateInterviewFn({
      idToken,
      allowList,
      timeTarget,
      maxQuestions,
      duration,
      name,
    })
      .then((result) => {
        console.log(result.data);
        setOutput({
          status: "success",
          title: "Generated coding interview",
          description: (
            <Text>
              Generated with {result.data.match_threshold * 100}% fidelity to
              time target. Go take a look
              <Link to={"/edit_interview/" + result.data.interview_id}>
                here
              </Link>
              .
            </Text>
          ),
        });
      })
      .catch((error) => {
        console.warn(error);
        setOutput({
          status: "error",
          title: "Could not generate coding interview",
          description: error.message,
        });
      });
  };

  return (
    <>
      {isAdmin && (
        <VStack>
          <Heading mb={4}>Admin Panel</Heading>
          {Object.keys(output).length > 0 && !output.loading && (
            <Alert
              status={output["status"]}
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              mb={4}
              rounded="2px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {output["title"]}
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                {output["description"]}
              </AlertDescription>
            </Alert>
          )}
          <HStack>
            <Button
              colorScheme="primary"
              onClick={generateInterview}
              isLoading={output.loading}
            >
              Generate Coding Interview
            </Button>
          </HStack>
        </VStack>
      )}
    </>
  );
};

export default function AdminPanel() {
  return (
    <IfSignedIn
      passUser={true}
      component={(user) => <AdminControls user={user} />}
    />
  );
}
