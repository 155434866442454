import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/latex_fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import customTheme from "./utils/theme";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/app-check";
import { firebaseConfig, appCheckKey } from "./utils/config";

import { FirebaseAuthProvider } from "@react-firebase/auth";

/* Initialize Firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize AppCheck */
// TEMPORARILY DISABLED
// firebase.appCheck().activate(appCheckKey, true);

/* Render main app */
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
        <ColorModeScript
          initialColorMode={customTheme.config.initialColorMode}
        />
        <App />
      </FirebaseAuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
