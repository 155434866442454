import { useColorModeValue, useToken } from "@chakra-ui/react";
import { IoHardwareChipOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import * as React from "react";

export const Logo = ({ color = "", size = "40" }) => {
  const svgColor: [string, string] = useToken("colors", [
    "primary.500",
    "pink.400",
  ]);

  if (!color.length) {
    color = useColorModeValue(...svgColor);
  }

  return (
    <IconContext.Provider value={{ color }}>
      <IoHardwareChipOutline size={size} />
    </IconContext.Provider>
  );
};

export const CompanyName = (props) => <>Overclockr</>;
