import filesystem from "../data/filesystem.json";

async function findMatch(partialPath) {
  // TODO: Enable auto-download functionality
  return undefined;
}

/****************************************************************/
// fake files

let files = [];
let urlRoot = "";
let currentDirectory = "";
let texput;
let texputaux = undefined;
let texmf = {};

export function deleteEverything() {
  files = [];
}

export function setTexput(buffer) {
  texput = Buffer.from(buffer);
}

export function setTexputAux(buffer) {
  // console.log("settexput", buffer);
  texputaux = buffer;
}

export function setTexmfExtra(t) {
  texmf = t;
}

export function writeFileSync(filename, buffer) {
  files.push({
    filename,
    position: 0,
    erstat: 0,
    buffer: new Uint8Array(Buffer.from(buffer)),
    descriptor: files.length,
  });
}

export function readFileSync(filename) {
  for (const f of files) {
    if (f.filename == filename) {
      if (f.position != 0) {
        if (f.buffer) return f.buffer.slice(0, f.position);
        throw Error(`Missing buffer for filename ${f.filename}`);
      }
    }
  }

  throw Error(`Could not find file ${filename}`);
}

let sleeping = false;
function openSync(filename, mode) {
  // console.log("attempting to open", filename, "in mode", mode);

  // FIXME: this seems like a bug with TeXlive?
  // eslint-disable-next-line quotes
  if (filename.startsWith('"')) {
    filename = filename.replace(/"/g, "");
  }

  if (filename === "texput.aux") {
    let buffer = new Uint8Array();
    if (texputaux) {
      // console.log("BUFFER=", buffer);
      buffer = new Uint8Array(texputaux);
    }

    files.push({
      filename,
      position: 0,
      position2: 0,
      erstat: 0,
      buffer: buffer,
      descriptor: files.length,
    });
    // console.log("opened with handle", files.length - 1);
    return files.length - 1;
  }

  if (filename === "texput.dvi") {
    files.push({
      filename,
      position: 0,
      position2: 0,
      erstat: 0,
      buffer: new Uint8Array(),
      descriptor: files.length,
    });
    // console.log("opened with handle", files.length - 1);
    return files.length - 1;
  }

  if (filename in filesystem) {
    let buffer = Buffer.from(filesystem[filename], "base64");

    files.push({
      filename,
      position: 0,
      position2: 0,
      erstat: 0,
      buffer: buffer,
      descriptor: files.length,
    });
    // console.log("opened with handle", files.length - 1);
    return files.length - 1;
  }

  if (filename === "texput.tex") {
    files.push({
      filename,
      position: 0,
      position2: 0,
      erstat: 0,
      buffer: new Uint8Array(texput),
      descriptor: files.length,
    });
    // console.log("opened with handle", files.length - 1);
    return files.length - 1;
  }

  if (filename === "texput.log") {
    files.push({
      filename,
      position: 0,
      position2: 0,
      erstat: 0,
      buffer: new Uint8Array(),
      descriptor: files.length,
    });
    // console.log("opened with handle", files.length - 1);
    return files.length - 1;
  }

  if (texmf[filename]) {
    const enc = new TextEncoder(); // always utf-8

    files.push({
      filename,
      position: 0,
      position2: 0,
      erstat: 0,
      buffer: enc.encode(texmf[filename]),
      descriptor: files.length,
    });
    // console.log("opened with handle", files.length - 1);
    return files.length - 1;
  }

  if (!sleeping) {
    startUnwind();
    sleeping = true;

    findMatch(filename).then((fullFilename) => {
      if (filename == "pgfsys-ximera.def")
        fullFilename = "/local-texmf/tex/latex/ximeraLatex/pgfsys-ximera.def";

      // console.log("File does not exist:", filename);

      files.push({
        filename,
        position: 0,
        position2: 0,
        erstat: mode == "r" ? 1 : 0,
        buffer: new Uint8Array(),
        descriptor: files.length,
      });
      startRewind();
    });
  } else {
    stopRewind();
    sleeping = false;

    return files.length - 1;
  }
}

function closeSync(fd) {
  // ignore this.
}

function writeSync(
  file,
  buffer,
  pointer = 0,
  length = buffer.length - pointer
) {
  while (length > file.buffer.length - file.position) {
    const b = new Uint8Array(1 + file.buffer.length * 2);
    b.set(file.buffer);
    file.buffer = b;
  }

  file.buffer
    .subarray(file.position)
    .set(buffer.subarray(pointer, pointer + length));
  file.position += length;
}

function readSync(file, buffer, pointer, length, seek) {
  if (pointer === undefined) pointer = 0;
  if (length === undefined) length = buffer.length - pointer;

  if (length > file.buffer.length - seek) length = file.buffer.length - seek;

  buffer.subarray(pointer).set(file.buffer.subarray(seek, seek + length));

  return length;
}

/****************************************************************/
// fake process.write.stdout

let consoleBuffer = "";

function writeToConsole(x) {
  consoleBuffer += x;
  if (consoleBuffer.indexOf("\n") >= 0) {
    const lines = consoleBuffer.split("\n");
    consoleBuffer = lines.pop() || "";
    for (const line of lines) {
      console.log(line);
    }
  }
}

const process = {
  stdout: {
    write: writeToConsole,
  },
};

/****************************************************************/
// setup

let memory;
let inputBuffer = "";
let callback = function () {
  throw Error("callback undefined");
};
let view;

let wasmExports;

export function setDirectory(d) {
  currentDirectory = d;
}

export function setMemory(m) {
  memory = m;
  view = new Int32Array(m);
}

export function setWasmExports(m) {
  wasmExports = m;
}

export function setUrlRoot(u) {
  urlRoot = u;
}

export function setCallback(cb) {
  callback = cb;
}

export function setConsoleWriter(cb) {
  process.stdout.write = cb;
}

export function setInput(input) {
  inputBuffer = input;
}

const DATA_ADDR = 1170 * 1024 * 64;
const END_ADDR = 1270 * 1024 * 64;
let windingDepth = 0;

function startUnwind() {
  if (view) {
    view[DATA_ADDR >> 2] = DATA_ADDR + 8;
    view[(DATA_ADDR + 4) >> 2] = END_ADDR;
  }

  wasmExports.asyncify_start_unwind(DATA_ADDR);
  windingDepth += 1;
}

function startRewind() {
  wasmExports.asyncify_start_rewind(DATA_ADDR);

  try {
    wasmExports.main();
  } catch (RuntimeError) {
    console.warn("Caught RuntimeError");
  }

  if (windingDepth == 0) {
    callback();
  }
}

function stopRewind() {
  windingDepth -= 1;
  wasmExports.asyncify_stop_rewind();
}

/****************************************************************/
// provide time back to tex

export function getCurrentMinutes() {
  const d = new Date();
  return 60 * d.getHours() + d.getMinutes();
}

export function getCurrentDay() {
  return new Date().getDate();
}

export function getCurrentMonth() {
  return new Date().getMonth() + 1;
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

/****************************************************************/
// print

export function printString(descriptor, x) {
  const file = descriptor < 0 ? { stdout: true } : files[descriptor];
  const length = new Uint8Array(memory, x, 1)[0];
  const buffer = new Uint8Array(memory, x + 1, length);
  const string = String.fromCharCode.apply(null, Array.from(buffer));

  if (file.stdout) {
    process.stdout.write(string);
    return;
  }

  writeSync(file, Buffer.from(string));
}

export function printBoolean(descriptor, x) {
  const file = descriptor < 0 ? { stdout: true } : files[descriptor];

  const result = x ? "TRUE" : "FALSE";

  if (file.stdout) {
    process.stdout.write(result);
    return;
  }

  writeSync(file, Buffer.from(result));
}
export function printChar(descriptor, x) {
  const file = descriptor < 0 ? { stdout: true } : files[descriptor];
  if (file.stdout) {
    process.stdout.write(String.fromCharCode(x));
    return;
  }

  const b = Buffer.alloc(1);
  b[0] = x;
  writeSync(file, b);
}

export function printInteger(descriptor, x) {
  const file = descriptor < 0 ? { stdout: true } : files[descriptor];
  if (file.stdout) {
    process.stdout.write(x.toString());
    return;
  }

  writeSync(file, Buffer.from(x.toString()));
}

export function printFloat(descriptor, x) {
  const file = descriptor < 0 ? { stdout: true } : files[descriptor];
  if (file.stdout) {
    process.stdout.write(x.toString());
    return;
  }

  writeSync(file, Buffer.from(x.toString()));
}

export function printNewline(descriptor, x) {
  const file = descriptor < 0 ? { stdout: true } : files[descriptor];

  if (file.stdout) {
    process.stdout.write("\n");
    return;
  }

  writeSync(file, Buffer.from("\n"));
}

export function reset(length, pointer) {
  const buffer = new Uint8Array(memory, pointer, length);
  let filename = String.fromCharCode.apply(null, Array.from(buffer));

  filename = filename.replace(/ +$/g, "");
  filename = filename.replace(/^\*/, "");
  filename = filename.replace(/^TeXfonts:/, "");

  if (filename == "TeXformats:TEX.POOL") filename = "tex.pool";

  if (filename == "TTY:") {
    files.push({
      filename: "stdin",
      stdin: true,
      position: 0,
      position2: 0,
      erstat: 0,
    });
    return files.length - 1;
  }

  return openSync(filename, "r");
}

export function rewrite(length, pointer) {
  const buffer = new Uint8Array(memory, pointer, length);
  let filename = String.fromCharCode.apply(null, Array.from(buffer));

  filename = filename.replace(/ +$/g, "");

  if (filename == "TTY:") {
    files.push({
      filename: "stdout",
      stdout: true,
      position: 0,
      erstat: 0,
    });
    return files.length - 1;
  }

  return openSync(filename, "w");
}

export function close(descriptor) {
  const file = files[descriptor];

  if (file.descriptor) closeSync(file.descriptor);
}

export function eof(descriptor) {
  const file = files[descriptor];

  if (file.eof) return 1;
  return 0;
}

export function erstat(descriptor) {
  const file = files[descriptor];
  return file.erstat;
}

export function eoln(descriptor) {
  const file = files[descriptor];

  if (file.eoln) return 1;
  return 0;
}

export function get(descriptor, pointer, length) {
  const file = files[descriptor];

  const buffer = new Uint8Array(memory);

  if (file.stdin) {
    if (file.position >= inputBuffer.length) {
      buffer[pointer] = 13;
      file.eof = true;
      file.eoln = true;
    } else buffer[pointer] = inputBuffer[file.position].charCodeAt(0);
  } else if (file.descriptor) {
    if (readSync(file, buffer, pointer, length, file.position) == 0) {
      buffer[pointer] = 0;
      file.eof = true;
      file.eoln = true;
      return;
    }
  } else {
    file.eof = true;
    file.eoln = true;
    return;
  }

  file.eoln = false;
  if (buffer[pointer] == 10) file.eoln = true;
  if (buffer[pointer] == 13) file.eoln = true;

  file.position += length;
}

export function put(descriptor, pointer, length) {
  const file = files[descriptor];

  const buffer = new Uint8Array(memory);

  writeSync(file, buffer, pointer, length);
}

export function getfilesize(length, pointer) {
  var buffer = new Uint8Array(memory, pointer, length);
  var filename = String.fromCharCode.apply(null, buffer);

  return 0;
}

export function snapshot() {
  console.log("(-snapshot-)");
  return 1;
}

export function inputln(
  descriptor,
  bypass_eoln,
  bufferp,
  firstp,
  lastp,
  max_buf_stackp,
  buf_size
) {
  var file = files[descriptor];
  var last_nonblank = 0; // |last| with trailing blanks removed

  var buffer = new Uint8Array(memory, bufferp, buf_size);
  var first = new Uint32Array(memory, firstp, 1);
  var last = new Uint32Array(memory, lastp, 1);
  // FIXME: this should not be ignored
  var max_buf_stack = new Uint32Array(memory, max_buf_stackp, 1);

  if (file.stdin) {
    file.buffer = inputBuffer;
  }

  if (file.content === undefined) {
    file.content = Buffer.from(file.buffer);
  }

  // cf.\ Matthew 19\thinspace:\thinspace30
  last[0] = first[0];

  // input the first character of the line into |f^|
  if (bypass_eoln) {
    if (!file.eof) {
      if (file.eoln) {
        file.position2 = file.position2 + 1;
      }
    }
  }

  let endOfLine = file.content.indexOf(10, file.position2);
  if (endOfLine < 0) {
    endOfLine = file.content.length;
  }

  if (file.position2 >= file.content.length) {
    if (file.stdin) {
      if (callback) callback();
    }

    file.eof = true;
    return false;
  } else {
    buffer.set(file.content.slice(file.position2, endOfLine), first[0]);
    const bytesCopied = file.content.slice(file.position2, endOfLine).length;

    last[0] = first[0] + bytesCopied;

    while (buffer[last[0] - 1] == 32) last[0] = last[0] - 1;

    file.position2 = endOfLine;
    file.eoln = true;
  }

  return true;
}

export function evaljs(
  str_number,
  str_poolp,
  str_startp,
  pool_ptrp,
  pool_size,
  max_strings,
  eqtbp,
  active_base,
  eqtb_size,
  count_base
) {
  var str_start = new Uint32Array(memory, str_startp, max_strings + 1);
  var pool_ptr = new Uint32Array(memory, pool_ptrp, 1);
  var str_pool = new Uint8Array(memory, str_poolp, pool_size + 1);
  var length = str_start[str_number + 1] - str_start[str_number];
  var input = new Uint8Array(memory, str_poolp + str_start[str_number], length);
  var string = new TextDecoder("ascii").decode(input);

  var count = new Uint32Array(
    memory,
    eqtbp + 8 * (count_base - active_base),
    512
  );

  const handler = {
    get: function (target, prop, receiver) {
      return target[2 * prop];
    },
    set: function (target, prop, value) {
      target[2 * prop] = value;
    },
  };

  var tex = {
    print: function (s) {
      const encoder = new TextEncoder("ascii");
      const view = encoder.encode(s);
      const b = Buffer.from(view);
      str_pool.set(b, pool_ptr[0]);
      pool_ptr[0] += view.length;
    },
    count: new Proxy(count, handler),
  };

  var f = Function(["tex"], string);
  f(tex);
}
