import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertStatus,
} from "@chakra-ui/react";

export default function CenteredAlert({
  title,
  description,
  status = "error",
  variant = "subtle",
  height = "200px",
  width = "200px",
}) {
  return (
    <Alert
      status={status as AlertStatus}
      variant={variant}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      minHeight={height}
      minWidth={width}
      rounded="8px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {title}
      </AlertTitle>
      <AlertDescription maxWidth="sm">{description}</AlertDescription>
    </Alert>
  );
}
