import {
  Box,
  Stack,
  ButtonGroup,
  ButtonGroupProps,
  IconButton,
  Text,
  TextProps,
  Divider,
  HStack,
  Heading,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import * as React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { CompanyName, Logo } from "./Logo";

const Copyright = (props: TextProps) => (
  <Text fontSize="sm" {...props}>
    &copy; {new Date().getFullYear()} <CompanyName /> Inc.
    {/*  All rights reserved. */}
  </Text>
);

const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      href="#"
      aria-label="LinkedIn"
      icon={<FaLinkedin fontSize="20px" />}
    />
    <IconButton
      as="a"
      href="#"
      aria-label="GitHub"
      icon={<FaGithub fontSize="20px" />}
    />
    <IconButton
      as="a"
      href="#"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
    />
  </ButtonGroup>
);

export const Footer = () => {
  const color = useColorModeValue(
    ...(useToken("colors", ["primary.700", "white"]) as [string, string])
  );
  return (
    <>
      <Divider />
      <Box
        as="footer"
        role="contentinfo"
        mx="auto"
        maxW="7xl"
        pt="4"
        pb="6"
        px={{ base: "4", md: "8" }}
      >
        <Stack>
          <Stack
            direction="row"
            spacing="4"
            align="center"
            justify="space-between"
            mb={-1}
          >
            <HStack>
              <Logo color={color} size="30" />
              <Box>
                <Copyright
                  color={color}
                  alignSelf={{ base: "center", sm: "start" }}
                />
              </Box>
            </HStack>
            <SocialMediaLinks />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
