import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Spinner,
  SimpleGrid,
  Heading,
  VStack,
  ScaleFade,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import "../App.css";

import { Link as RouterLink } from "react-router-dom";

import Table from "../components/Table";
import IfSignedIn from "../components/IfSignedIn";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import Markdown from "../components/Markdown";
import Workspace from "../components/Workspace";

import LatexUtils from "../utils/latex";

const renderSetup = ({ question, params, isPreview, latexUtils }) => {
  return (
    <>
      <Heading size="lg">{question.variants[params.variant_id].title}</Heading>
      <Heading my={4} size="md">
        Problem Setup
      </Heading>
      <Markdown markdown={question.setup} latexUtils={latexUtils} />
    </>
  );
};

const renderWorkArea = ({ question, params, isPreview, latexUtils }) => {
  const groupIds = question.variants[params.variant_id].groups;
  const usingGroups = groupIds.map((gid) => question.groups[gid]);
  return (
    <Workspace
      qid={params.question_id}
      groups={usingGroups}
      groupIds={groupIds}
      responses={question.responses}
      params={params}
      latexUtils={latexUtils}
      isPreview={isPreview}
      question={question}
    />
  );
};

const renderQuestion = ({
  question,
  params,
  isPreview,
  colorPrefix,
  latexUtils,
}) => {
  const childProps = { question, params, isPreview, latexUtils };
  return (
    <SimpleGrid columns={2} spacing={2} width={"97vw"}>
      <ScaleFade initialScale={0.9} in={true}>
        <Box
          className="Setup-render render-box"
          boxShadow="inner"
          bg={`${colorPrefix}`}
        >
          <Box className="render-box-content">{renderSetup(childProps)}</Box>
        </Box>
      </ScaleFade>
      <ScaleFade initialScale={0.9} in={true}>
        <Box
          className="Work-render render-box"
          boxShadow="inner"
          bg={`${colorPrefix}`}
        >
          <Box className="render-box-content">{renderWorkArea(childProps)}</Box>
        </Box>
      </ScaleFade>
    </SimpleGrid>
  );
};

const QuestionArea = ({ params }) => {
  const [isLoading, setIsLoading] = useState(3);
  const [isPreview, setIsPreview] = useState(null as any);
  const [question, setQuestion] = useState(null as any);
  const [latexUtils, setLatexUtils] = useState(null as any);
  const [loadingMessage, setLoadingMessage] = useState(
    "Fetching interview question..."
  );
  const colorPrefix = useColorModeValue("gray.200", "gray.700");
  const toast = useToast();

  const fetchQuestion = async () => {
    if (isLoading != 3) return;
    setIsLoading(2);

    const retrieveQuestion = firebase
      .functions()
      .httpsCallable("api/fetchOrPreviewQuestion");

    const idToken = await firebase.auth().currentUser!.getIdToken(true);
    let questionData: any = {};

    try {
      questionData = await retrieveQuestion({
        variantId: params.variant_id,
        questionId: params.question_id,
        interviewId: params.interview_id,
        idToken,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Could not load question.",
        description: "We were unable to retrieve the given question for you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      const { question, responses, isPreview } = questionData.data;
      question.responses = responses;

      await setQuestion(question);
      await setIsPreview(isPreview);
      await setLoadingMessage("Loading environment...");
      await setIsLoading(1);

      const latex = new LatexUtils();
      await latex.load();

      setLoadingMessage("Done!");
      setLatexUtils(latex);
      setIsLoading(0);
    } catch (e) {
      console.error(e);
      toast({
        title: "Could not render question.",
        description: "We were unable to render the given question for you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, [isLoading]);

  return (
    <Box>
      {isLoading > 0 ? (
        <VStack>
          <Text>{loadingMessage}</Text>
          <Spinner color="red.500" size="lg" thickness="4px" />
        </VStack>
      ) : (
        renderQuestion({ question, params, isPreview, colorPrefix, latexUtils })
      )}
    </Box>
  );
};

export default function Question(props) {
  return (
    <IfSignedIn
      component={() => <QuestionArea params={props.match.params} />}
    />
  );
}
