import React from "react";

import { Box, Text, Link, VStack } from "@chakra-ui/react";

import firebase from "firebase/app";
import { FirebaseAuthConsumer } from "@react-firebase/auth";

import AccountForm from "../components/AccountForm";

import { Link as RouterLink, Redirect } from "react-router-dom";

export default function Signin() {
  const userLogin = function ({ email, password }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          resolve({
            title: "Authenticated",
            description: "Successfully logged in!",
          });
        })
        .catch((error) => {
          console.warn(error);
          const err = {
            title: "Could not authenticate",
            description: error.message,
          };
          if (error.code === "auth/wrong-password") {
            err.description = "Either the email or password is incorrect.";
          }
          reject(err);
        });
    });
  };

  return (
    <Box>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user }) => {
          if (isSignedIn) {
            return (
              <Text>
                You are already signed in. Redirecting you to your dashboard{" "}
                <Link as={RouterLink} to="/dashboard" color="teal.500">
                  here
                </Link>
                ...
                <Redirect to="/dashboard" />
              </Text>
            );
          } else {
            return (
              <VStack spacing={8}>
                <AccountForm
                  title="Sign in to your account"
                  sublineText={"Don't have an account?"}
                  linkText={"Create one here."}
                  linkHref={"/signup"}
                  onSubmit={userLogin}
                  submitText="Sign In"
                />
                <Text>
                  (If you forgot your password, click{" "}
                  <Link
                    onClick={(evt) => {
                      alert("Not implemented yet!");
                    }}
                    color="teal.500"
                  >
                    here
                  </Link>
                  .)
                </Text>
              </VStack>
            );
          }
        }}
      </FirebaseAuthConsumer>
    </Box>
  );
}
