import React, { useState } from "react";

import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { Link } from "./Utilities";

const AccountForm = ({
  onSubmit,
  title,
  submitText,
  sublineText,
  linkText,
  linkHref,
  minWidth = "400px",
  maxWidth = "550px",
  withEmail = true,
  withPassword = true,
  withName = false,
  isRequired = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const name = event.target?.name?.value;
    const email = event.target?.email?.value;
    const password = event.target?.password?.value;

    setIsLoading(true);

    try {
      const successMessage = await onSubmit({ name, email, password });
      toast({
        ...successMessage,
        status: "success",
        duration: 2500,
        isClosable: true,
      });
      setIsLoading(false);
      setShowPassword(false);
    } catch (errorMessage) {
      toast({
        ...(errorMessage as any),
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      setIsLoading(false);
      setShowPassword(false);
    }
  };

  const handlePasswordVisibility = (e) => {
    e.target.blur();
    setShowPassword(!showPassword);
  };

  // <Logo mx="auto" h="8" iconColor={mode("blue.600", "blue.200")} />

  return (
    <>
      <Box maxW={{ sm: "md" }} mx={{ sm: "auto" }} w={{ sm: "full" }}>
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          {title}
        </Heading>
        <Text mt="4" align="center" maxW="md" fontWeight="medium">
          <span>{sublineText}</span>
          <Link to={linkHref}>{linkText}</Link>
        </Text>
      </Box>
      <Flex width="full" align="center" justifyContent="center">
        <Box
          p={8}
          maxWidth={maxWidth}
          minWidth={minWidth}
          borderWidth={1}
          borderRadius={8}
          boxShadow="lg"
          bg={useColorModeValue("white", "gray.700")}
        >
          <Box my={4} textAlign="left">
            <form onSubmit={handleSubmit}>
              <VStack spacing={6}>
                {withName && (
                  <FormControl isRequired={isRequired}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Steve Jobs"
                      size="lg"
                      name="name"
                    />
                  </FormControl>
                )}
                {withEmail && (
                  <FormControl isRequired={isRequired}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      placeholder="steve@apple.com"
                      size="lg"
                      name="email"
                    />
                  </FormControl>
                )}
                {withPassword && (
                  <FormControl isRequired={isRequired}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="**********"
                        minLength={6}
                        size="lg"
                        name="password"
                        autoComplete="on"
                      />
                      <InputRightElement width="3rem">
                        <IconButton
                          h="1.5rem"
                          size="sm"
                          onClick={handlePasswordVisibility}
                          variant="ghost"
                          icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          aria-label="Toggle password display"
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
                <Button
                  colorScheme="primary"
                  variant="solid"
                  type="submit"
                  width="full"
                  onClick={(event) => (event.target as any)!.blur()}
                  isLoading={isLoading}
                >
                  {submitText}
                </Button>
              </VStack>
            </form>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default AccountForm;
