import React from "react";
import {
  Box,
  BoxProps,
  Heading,
  Text,
  Flex,
  Icon,
  Grid,
  GridItem,
  Badge,
  Button,
  ButtonProps,
  SimpleGrid,
  List,
  ListIcon,
  ListItem,
  VStack,
  useColorModeValue as mode,
  ScaleFade,
} from "@chakra-ui/react";
import {
  PhoneIcon,
  AddIcon,
  WarningIcon,
  StarIcon,
  CheckIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import { SiHive, SiMarketo, SiMicrosoft } from "react-icons/si";
import { HiCheckCircle } from "react-icons/hi";

export function PricingLong() {
  return (
    <Box>
      <Box>
        <Text textAlign="center" fontSize="4xl">
          Find the right package for your hiring activity.
        </Text>
        <Text p="4" textAlign="center" fontSize="2xl">
          Our plans are designed to accommodate everyone from startups to large
          corporations. With quality content and a seamless interviewing
          platform, you won&apos;t be disappointed.
        </Text>
      </Box>

      <Box>
        <Grid templateColumns="repeat(3, 1fr)" gap={1}>
          <Box p="5" _hover={{ boxShadow: "dark-lg" }}>
            <Box w="100%" h="3" bgGradient="linear(to-r, blue.200, gray.600)" />
            <Box h="550px" p="1" bg="rgba(100, 150, 220, 0.1)">
              <Box textAlign="center" margin="auto">
                <Badge
                  p="3"
                  fontSize="2xl"
                  color="rgba(63, 128, 190, 1)"
                  bg="rgba(0, 0, 0, 0)"
                >
                  Standard
                </Badge>
              </Box>
              <Box p="2" h="80px">
                <Text textAlign="left" fontSize="sm">
                  For the small team looking to add a few new members, with a
                  focus on remote candidate screening.
                </Text>
              </Box>
              <Box fontSize="lg" p="1" margin="auto" textAlign="center">
                $25
                <Text as="span" color="gray.600" fontSize="lg">
                  / month
                </Text>
              </Box>
              <Box p="3" margin="auto" textAlign="center">
                <Box
                  boxShadow="2xl"
                  rounded="md"
                  p="2"
                  as="button"
                  borderRadius="md"
                  bg="blue.800"
                  color="white"
                  _hover={{ background: "blue.800", boxShadow: "dark-lg" }}
                >
                  Buy Now!
                </Box>
              </Box>
              <Box p="5">
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    1 Recruiter Account
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    2 Interviewer licenses
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    7 Live Interviews / month
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      15 Remote Interviews / month
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      1 Category, Basic Question Repository
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <StarIcon color="rgba(180, 150, 46, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      Expanded Question Repositories Available
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box p="5" _hover={{ boxShadow: "dark-lg" }}>
            <Box
              w="100%"
              h="3"
              bgGradient="linear(to-r, purple.400, #3e3945)"
            />
            <Box h="550px" p="1" bg="rgba(200, 100, 220, 0.1)">
              <Box textAlign="center" margin="auto">
                <Badge
                  p="3"
                  fontSize="2xl"
                  color="rgba(111, 63, 143, 1)"
                  bg="rgba(0, 0, 0, 0)"
                >
                  Premium
                </Badge>
              </Box>
              <Box p="2" h="80px">
                <Text textAlign="left" fontSize="sm">
                  Work across teams and departments to find the right candidates
                  for a variety of positions.
                </Text>
              </Box>
              <Box fontSize="lg" p="1" margin="auto" textAlign="center">
                $120
                <Box as="span" color="gray.600" fontSize="lg">
                  / month
                </Box>
              </Box>
              <Box p="3" margin="auto" textAlign="center">
                <Box
                  boxShadow="2xl"
                  rounded="md"
                  p="2"
                  as="button"
                  borderRadius="md"
                  bg="purple.800"
                  color="white"
                  _hover={{ background: "purple.800", boxShadow: "dark-lg" }}
                >
                  Buy Now!
                </Box>
              </Box>
              <Box p="5">
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    2 Recruiter Accounts
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    5 Interviewer licenses
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    30 Live Interviews / license
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      50 Remote Interviews / month
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      3 Categories, Enhanced Question Repositories
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      Content and Feature Requests
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <StarIcon color="rgba(180, 150, 46, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      (Discounted) Expanded Question Repositories Available
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box p="5" _hover={{ boxShadow: "dark-lg" }}>
            <Box w="100%" h="3" bgGradient="linear(to-r, red.300, #564855)" />
            <Box h="550px" p="1" bg="rgba(200, 50, 100, 0.1)">
              <Box textAlign="center" margin="auto">
                <Badge
                  p="3"
                  fontSize="2xl"
                  color="#b02c46"
                  bg="rgba(0, 0, 0, 0)"
                >
                  Rapid Scaling
                </Badge>
              </Box>
              <Box p="2" h="80px">
                <Text textAlign="left" fontSize="sm">
                  Expand your corporation as fast as your hiring budget allows,
                  with this all-inclusive package built to accomodate heavy-duty
                  recruiting.
                </Text>
              </Box>
              <Box fontSize="lg" p="1" margin="auto" textAlign="center">
                $750
                <Box as="span" color="gray.600" fontSize="lg">
                  / month
                </Box>
              </Box>
              <Box p="3" margin="auto" textAlign="center">
                <Box
                  boxShadow="2xl"
                  rounded="md"
                  p="2"
                  as="button"
                  borderRadius="md"
                  bg="red.800"
                  color="white"
                  _hover={{ background: "red.800", boxShadow: "dark-lg" }}
                >
                  Buy Now!
                </Box>
              </Box>
              <Box p="5">
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    Unlimited Recruiter accounts
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    20 Interviewer licenses
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Text textAlign="left" fontSize="sm">
                    Unlimited Live Interviews / license
                  </Text>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      200 Remote Interviews / month
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      All Categories, Complete Question Repositories
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      Content and Feature Requests
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      Custom Platform and Workflow
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" alignItems="center">
                  <Box d="grid" pl="1" pr="3" py="2">
                    {" "}
                    <CheckCircleIcon color="rgba(8, 111, 66, 1)" />{" "}
                  </Box>
                  <Box>
                    <Text textAlign="left" fontSize="sm">
                      Designated Interviewing Liaison
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

// const templatePricingBox({ accentColor, boxColor, tierName, price }) => {
//   return (
//     <Box p="5">
//         <Box w="40" h="3" bg={accentColor} />
//         <Box  p="5" d="flex" alignItems="baseline" w="40" h="80" bg={boxColor}>
//           <Text p="1" marginLeft="auto" marginRight="auto" textAlign="center" px="5">
//             {tierName}
//           </Text>
//           <Text p="1" marginLeft="auto" marginRight="auto" textAlign="center" borderRadius="full" px="5">
//             {price}
//           </Text>
//         </Box>
//     </Box>
//   )
// }

export interface CardProps extends BoxProps {
  isPopular?: boolean;
}

export const Card: React.FC<CardProps> = (props) => {
  const { children, isPopular, ...rest } = props;
  return (
    <Box
      position="relative"
      px="6"
      pb="6"
      pt="16"
      overflow="hidden"
      bg={mode("white", "gray.700")}
      shadow="lg"
      maxW="md"
      width="100%"
      {...rest}
    >
      {isPopular && (
        <Flex
          bg={mode("primary.500", "primary.200")}
          position="absolute"
          right={-20}
          top={6}
          width="240px"
          transform="rotate(45deg)"
          py={2}
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontSize="xs"
            textTransform="uppercase"
            fontWeight="bold"
            letterSpacing="wider"
            color={mode("white", "gray.800")}
          >
            Popular
          </Text>
        </Flex>
      )}
      {children}
    </Box>
  );
};

export interface PricingCardData {
  features: string[];
  name: string;
  price: string;
}

interface PricingCardProps extends CardProps {
  data: PricingCardData;
  icon: React.ElementType;
  button: React.ReactElement;
}

export const PricingCard = (props: PricingCardProps) => {
  const { data, icon, button, ...rest } = props;
  const { features, price, name } = data;
  const accentColor = mode("primary.600", "primary.200");

  return (
    <Card rounded={{ sm: "xl" }} {...rest}>
      <VStack spacing={6}>
        <Icon aria-hidden as={icon} fontSize="4xl" color={accentColor} />
        <Heading size="lg" fontWeight="extrabold">
          {name}
        </Heading>
      </VStack>
      <Flex
        align="flex-end"
        justify="center"
        fontWeight="extrabold"
        color={accentColor}
        my="8"
      >
        <Heading size="4xl" fontWeight="inherit" lineHeight="0.9em">
          {price}
        </Heading>
        <Text fontWeight="inherit" fontSize="2xl">
          / mo
        </Text>
      </Flex>
      <List spacing="4" mb="8" maxW="38ch" mx="auto">
        {features.map((feature, index) => (
          <ListItem fontWeight="medium" key={index}>
            <ListIcon
              fontSize="xl"
              as={HiCheckCircle}
              marginEnd={2}
              color={accentColor}
            />
            {feature}
          </ListItem>
        ))}
      </List>
      {button}
    </Card>
  );
};

const ActionButton = (props: ButtonProps) => (
  <Button
    colorScheme="primary"
    size="lg"
    w="full"
    fontWeight="extrabold"
    py="8"
    {...props}
  />
);

export default function Pricing() {
  return (
    <ScaleFade in={true} initialScale={0.9}>
      <VStack>
        <Heading size="xl">
          Find the right package for your hiring activity
        </Heading>
        <Box width="80%">
          <Text fontSize="xl" fontWeight={400}>
            Our plans are designed to accommodate everyone from startups to
            large corporations. With quality content and a seamless interviewing
            platform, you won&apos;t be disappointed.
          </Text>
        </Box>
      </VStack>
      <Box as="section" bg={mode("gray.50", "gray.800")} py="14">
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacing={{ base: "4", lg: "0" }}
          maxW="7xl"
          mx="auto"
          justifyItems="center"
          alignItems="center"
        >
          <PricingCard
            data={{
              price: "$129",
              name: "Standard",
              features: [
                "2 recruiter accounts included",
                "10 live interviews, $35 per additional",
                "20 remote interviews, $25 per additional",
                "1 question category, basic questions",
              ],
            }}
            icon={SiMicrosoft}
            button={
              <ActionButton variant="outline" borderWidth="2px">
                Buy now
              </ActionButton>
            }
          />
          <PricingCard
            zIndex={1}
            isPopular
            transform={{ lg: "scale(1.05)" }}
            data={{
              price: "$749",
              name: "Scaling",
              features: [
                "Unlimited recruiter accounts",
                "30 live interviews, $25 per additional",
                "50 remote interviews, $15 per additional",
                "All question categories, custom questions",
                "Designated support staff",
                "Custom platform and workflow",
              ],
            }}
            icon={SiHive}
            button={<ActionButton>Buy now</ActionButton>}
          />
          <PricingCard
            data={{
              price: "$329",
              name: "Premium",
              features: [
                "10 recruiter accounts included",
                "20 live interviews, $30 per additional",
                "30 remote interviews, $20 per additional",
                "3 question categories, premium questions",
              ],
            }}
            icon={SiMarketo}
            button={
              <ActionButton variant="outline" borderWidth="2px">
                Buy now
              </ActionButton>
            }
          />
        </SimpleGrid>
      </Box>
    </ScaleFade>
  );
}
