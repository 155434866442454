import React, { useState } from "react";
import {
  Text,
  Link,
  VStack,
  CircularProgress,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import { Link as RouterLink, Redirect } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/auth";

import AccountForm from "../components/AccountForm";

export default function VerifyAccount() {
  const [loading, setLoading] = useState(true);
  const [hasPassword, setHasPassword] = useState(false);
  if (loading && firebase.auth().isSignInWithEmailLink(window.location.href)) {
    const urlParams = new URLSearchParams(window.location.search);
    firebase
      .auth()
      .signInWithEmailLink(urlParams.get("email")!, window.location.href)
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        setLoading(error.message);
      });
  }
  if (loading === true) {
    return (
      <VStack>
        <Text>Verifying email link...</Text>
        <CircularProgress isIndeterminate color="teal.500" />
      </VStack>
    );
  } else if (loading === false) {
    const userSetPassword = ({ password }) => {
      const currentUser = firebase.auth()!.currentUser;
      console.log(currentUser);
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser!.email!,
        password
      );
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser!.unlink(firebase.auth.EmailAuthProvider.PROVIDER_ID)
          .then(() => {
            return firebase!
              .auth()!
              .currentUser!.linkWithCredential(credential);
          })
          .then(() => {
            resolve({
              title: "Associated password",
              description:
                "Successfully associated password with your account.",
            });
            setHasPassword(true);
          })
          .catch((err) => {
            console.warn(err);
            reject({
              title: "Error associating password",
              description:
                err?.message ?? "(an internal server error occurrred)",
            });
          });
      });
    };
    return (
      <VStack>
        <Alert status="success">
          <AlertIcon />
          {!hasPassword ? (
            <>Verified your account! Set your password below.</>
          ) : (
            <>
              View your interviews and actions{" "}
              <Link as={RouterLink} to="/dashboard" color="teal.500">
                here
              </Link>
              . Redirecting... <Redirect to="/dashboard" />
            </>
          )}
        </Alert>
        {!hasPassword && (
          <AccountForm
            title="Choose Password"
            sublineText={"Want to sign in instead?"}
            linkText={"Go here."}
            linkHref={"/login"}
            onSubmit={userSetPassword}
            submitText="Set Password"
            withEmail={false}
            isRequired={true}
          />
        )}
      </VStack>
    );
  } else {
    return (
      <VStack>
        <Alert status="error">
          <AlertIcon />
          There was an error verifying your account: {loading}
        </Alert>
      </VStack>
    );
  }
}
