/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Spinner,
  Heading,
  useToast,
} from "@chakra-ui/react";
import "../App.css";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import QuestionPart from "./QuestionPart";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const Workspace = ({
  qid,
  groups,
  groupIds,
  responses,
  params,
  isPreview,
  latexUtils,
  question,
}) => {
  const [indexes, setIndexes] = useState([0, 0]);
  const [groupIdx, partIdx] = indexes;

  const getKey = (groupIdx, partIdx) => {
    return groupIdx.toString() + "/" + partIdx.toString();
  };

  const initialState = {};
  for (const [groupKey, group] of Object.entries(responses ?? {})) {
    for (const [partKey, part] of Object.entries(group as never)) {
      initialState[
        getKey(
          (groupIds as any[]).findIndex((elem) => groupKey === elem),
          partKey
        )
      ] = {
        answer: part,
      };
    }
  }

  const [workState, setWorkState] = useState(initialState);

  const toast = useToast();

  const saveStateHandler = async (state, extra = null) => {
    console.log(
      "New state",
      state,
      "at question",
      qid,
      "in group",
      groupIdx,
      "on part",
      partIdx
    );

    if (question.answers && isPreview && state === "grade") {
      const usingState = {} as any;
      const usingWorkState = {
        ...workState,
        [getKey(groupIdx, partIdx)]: extra,
      };
      for (const [key, val] of Object.entries(usingWorkState)) {
        const [gid, strPid] = key.split("/");
        const pid = parseInt(strPid);
        const groupId = groupIds[gid];

        if (!(groupId in usingState)) {
          usingState[groupId] = [] as any;
        }

        if (usingState[groupId].length <= pid) {
          usingState[groupId] = (usingState[groupId] as any[]).concat(
            Array(pid + 1 - usingState[groupId].length).fill({} as any)
          );
        }

        usingState[groupId][pid] = {} as any;
        const answer = (val as any).answer ?? ([] as any[]);
        for (let aid = 0; aid < answer.length; aid++) {
          usingState[groupId][pid][aid] = answer[aid];
        }
        for (
          let aid = answer.length;
          aid < Object.keys(question.answers[groupId][pid]).length;
          aid++
        ) {
          usingState[groupId][pid][aid] = null;
        }
      }

      console.log("Answers:", question.answers);
      console.log("State:", usingState);
      console.log("Variables:", question.variables);

      const idToken = await firebase.auth().currentUser!.getIdToken(true);
      const previewQuestionGradeFn = firebase
        .functions()
        .httpsCallable("api/previewQuestionGrade");
      try {
        const result = await previewQuestionGradeFn({
          answers: { 0: question.answers },
          responses: { 0: usingState },
          variables: question.variables,
          idToken,
        });

        console.log(result.data);

        for (let i = 0; i < groupIds.length; i++) {
          const groupId = groupIds[i];
          console.log(`~~~ For Section #${i + 1} (${groupId}) ~~~`);
          const group = result.data[0][groupId];
          for (const [pid, part] of Object.entries(group)) {
            for (const [subpid, subpart] of Object.entries(part as any)) {
              let usingSubpart = subpart;
              if (Array.isArray(usingSubpart)) {
                usingSubpart = usingSubpart[0];
              }

              let desc = "(not filled out)";
              let status;
              if (usingSubpart === true) {
                desc = "correct";
                status = "success";
              } else if (usingSubpart === false) {
                desc = "wrong";
                status = "warning";
              } else if (usingSubpart !== null) {
                desc = "(unknown -- internal error?)";
                status = "error";
              }

              let extraDesc = "";
              if (Array.isArray(subpart)) {
                const arrType = Number.isFinite(subpart[1][0])
                  ? "numeric"
                  : "string";
                extraDesc = ` Valid ${arrType} answers include: ${(
                  subpart[1] as any[]
                ).join(", ")}`;
              }

              if (i == groupIdx && parseInt(pid) == partIdx) {
                toast({
                  title: "Graded Subpart",
                  description:
                    status !== "error"
                      ? `Your answer was ${desc}.${extraDesc}`
                      : "We were unable to grade the current subpart.",
                  status: status,
                  duration: 3000,
                  isClosable: true,
                });
              }

              console.log(
                `Part ${parseInt(pid) + 1}, Subpart ${
                  parseInt(subpid) + 1
                }: ${desc}.`
              );
            }
          }
        }
      } catch (e) {
        toast({
          title: "Could not load interview.",
          description:
            "We were unable to retrieve the given interview for you.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      return;
    }

    if (isPreview) {
      await timeout(500);
    } else if (state.answer !== undefined) {
      try {
        await firebase
          .firestore()
          .collection("sessions")
          .doc(params.interview_id)
          .update({
            [`responses.${qid}.${groupIds[groupIdx]}.${partIdx}`]: state.answer,
          });
      } catch (err) {
        console.error("Could not save question", err);
        toast({
          title: "Could not save answer",
          description: "We were unable to save the current answer for you.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }

    setWorkState({
      ...workState,
      [getKey(groupIdx, partIdx)]: state,
    });
  };

  const nextPartHandler = async (query = false) => {
    console.log("Requested next part", partIdx, groupIdx);

    if (isPreview) {
      await timeout(750);
    }

    if (groups[groupIdx].parts.length > partIdx + 1) {
      setIndexes([groupIdx, partIdx + 1]);
    } else if (groups.length > groupIdx + 1) {
      setIndexes([groupIdx + 1, 0]);
    }
  };

  const previousPartHandler = async (query = false) => {
    console.log("Requested previous part", partIdx, groupIdx);

    if (isPreview) {
      await timeout(750);
    }

    if (partIdx > 0) {
      setIndexes([groupIdx, partIdx - 1]);
    } else if (groupIdx > 0) {
      setIndexes([groupIdx - 1, groups[groupIdx - 1].parts.length - 1]);
    }
  };

  const queryPartHandler = () => {
    const hasNext =
      groups[groupIdx].parts.length > partIdx + 1 ||
      groups.length > groupIdx + 1;
    const hasBack = groupIdx > 0 || partIdx > 0;
    return { hasNext, hasBack };
  };

  const getInitialState = (groupIdx, partIdx) => {
    return workState[getKey(groupIdx, partIdx)] ?? {};
  };

  return (
    <Box height="inherit">
      <QuestionPart
        part={groups[groupIdx].parts[partIdx]}
        partIdx={partIdx}
        params={params}
        numParts={groups[groupIdx].parts.length}
        header={
          <Heading mb={4} size="lg">
            Section {groupIdx + 1}: {groups[groupIdx].title}
          </Heading>
        }
        saveStateHandler={saveStateHandler}
        nextPartHandler={nextPartHandler}
        previousPartHandler={previousPartHandler}
        queryPartHandler={queryPartHandler}
        latexUtils={latexUtils}
        initialState={getInitialState(groupIdx, partIdx)}
        key={getKey(groupIdx, partIdx)}
        isPreview={isPreview && question.answers} // only used downstream if isSuperAdmin (if answers included in question response from backend)
      />
    </Box>
  );
};

export default Workspace;
