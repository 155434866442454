import React, { useState, useEffect } from "react";
import { Box, Text, Button, Spinner, Heading } from "@chakra-ui/react";
import "../App.css";

import QNA from "./workspaces/QNA";

const QuestionPart = ({
  part,
  partIdx,
  params,
  numParts,
  saveStateHandler,
  nextPartHandler,
  previousPartHandler,
  queryPartHandler,
  latexUtils,
  header,
  initialState = {},
  isPreview = false,
}) => {
  // console.log(part, partIdx);

  const renderAppropriateWorkspace = (workspaceType) => {
    if (workspaceType == "q&a" || workspaceType == "qna") {
      return (
        <QNA
          part={part}
          params={params}
          saveStateHandler={saveStateHandler}
          nextPartHandler={nextPartHandler}
          previousPartHandler={previousPartHandler}
          queryPartHandler={queryPartHandler}
          initialState={initialState}
          latexUtils={latexUtils}
          isPreview={isPreview}
        />
      );
    } else {
      return <Text>Unable to render workspace of type {workspaceType}.</Text>;
    }
  };

  return (
    <Box height="inherit" display="flex" flexDirection="column">
      <Box>
        {header}
        {(partIdx > 0 || numParts > 1) && (
          <Heading size="md">
            Part {partIdx + 1} of {numParts}
          </Heading>
        )}
      </Box>
      <Box flexGrow={1}>
        {renderAppropriateWorkspace(part.interface.type ?? "qna")}
      </Box>
    </Box>
  );
};

export default QuestionPart;
