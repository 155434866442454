import pako from "pako";
import { ReadableStream } from "web-streams-polyfill";
import fetchStream from "fetch-readablestream";

import * as library from "./latex_library";

export default class LatexUtils {
  pages = 1270;
  coredump: Uint8Array | null = null;
  code: any;
  loaded = 0;
  urlRoot: string;
  tex: any;

  constructor() {
    const url = new URL(window.location.href);
    const host = url.host; // includes port number
    this.urlRoot = url.protocol + "//" + host + "/tex";
    this.tex = this.disallowConcurrency(this.texString.bind(this));
  }

  disallowConcurrency(fn) {
    // We do not want texString() to be called concurrently
    let inprogressPromise = Promise.resolve();

    return async (...args) => {
      await inprogressPromise;
      inprogressPromise = inprogressPromise.then(() => fn(...args));
      return inprogressPromise;
    };
  }

  async load() {
    if (this.loaded) return;

    console.log("Loading jsTeX...");
    this.loaded = 1;

    const tex = await fetch(this.urlRoot + "/tex-async.wasm");
    this.code = await WebAssembly.compile(await tex.arrayBuffer());

    const response = await fetchStream(this.urlRoot + "/core.dump.gz");
    const reader = response.body.getReader();
    const inf = new pako.Inflate();

    try {
      while (true) {
        const { done, value } = await reader.read();
        inf.push(value, done);
        if (done) break;
      }
    } finally {
      reader.releaseLock();
    }

    this.coredump = new Uint8Array(inf.result, 0, this.pages * 65536);
    this.loaded = 2;

    console.log("Locked and loaded.");
  }

  copy(src: Uint8Array) {
    const dst = new Uint8Array(src.length);
    dst.set(src);
    return dst;
  }

  async texString(input) {
    if (input.indexOf("{document}") === -1) {
      input = "\\begin{document}\n" + input + "\n\\end{document}\n";
    }

    library.deleteEverything();
    library.setTexput(input);
    const texmf = {};
    library.setTexmfExtra(texmf);
    library.setTexputAux(new Uint8Array());

    let lastStr = "";
    library.setConsoleWriter((x) => {
      lastStr += x;
      if (x.indexOf("\n") !== -1) {
        console.debug(lastStr);
        lastStr = "";
      }
    });

    const memory = new WebAssembly.Memory({
      initial: this.pages,
      maximum: this.pages,
    });

    const buffer = new Uint8Array(memory.buffer, 0, this.pages * 65536);
    buffer.set(this.copy(this.coredump!));

    library.setMemory(memory.buffer);
    // library.setInput(" sample.tex \n\\end\n");
    library.setInput(input);

    const wasm = await WebAssembly.instantiate(this.code, {
      library: library,
      env: { memory: memory },
    });

    library.setWasmExports((wasm as any).exports);

    return new Promise((resolve, reject) => {
      library.setCallback(() => {
        resolve(library.readFileSync("texput.dvi"));
      });

      (wasm as any).exports.main();
    });

    // let results = await WebAssembly.instantiate(code, {
    //   library: library,
    //   env: { memory: memory },
    // });
  }
}

/*window.onload = async function () {
  console.log("Called on load");
  await load();

  async function process(elt) {
    console.log("Processing element:", elt);
    var text = elt.childNodes[0].nodeValue;

    var div = document.createElement("div");

    let dvi = await tex(text);

    // console.log(btoa(String.fromCharCode(...dvi)));

    let html = "";
    const page = new Writable({
      write(chunk, encoding, callback) {
        html = html + chunk.toString();
        callback();
      },
    });

    async function* streamBuffer() {
      yield Buffer.from(dvi);
      return;
    }

    let machine = dvi2html(new JSBuffer(dvi), page);
    console.log(machine);
    console.log(html.slice(0, 1000) + "...");
    // div.style.position = "absolute";
    // div.style.left = "50%";
    // div.style.top = "50%";
    // div.style.width = "100%";
    div.style.display = "flex";
    div.style.width = machine.paperwidth.toString() + "pt";
    div.style.height = machine.paperheight.toString() + "pt";
    div.style["align-items"] = "center";
    div.style["justify-content"] = "center";

    div.innerHTML = html;
    // let svg = div.getElementsByTagName("svg");
    // svg[0].setAttribute("width", machine.paperwidth.toString() + "pt");
    // svg[0].setAttribute("height", machine.paperheight.toString() + "pt");
    // svg[0].setAttribute(
    //   "viewBox",
    //   `-72 -72 ${machine.paperwidth} ${machine.paperheight}`
    // );

    elt.parentNode.replaceChild(div, elt);
  }

  var scripts = document.getElementsByTagName("script");
  var tikzScripts = Array.prototype.slice
    .call(scripts)
    .filter((e) => e.getAttribute("type") === "text/tikz");

  console.log(scripts);
  console.log(tikzScripts);

  tikzScripts.reduce(async (promise, element) => {
    await promise;
    return process(element);
  }, Promise.resolve());
};*/
