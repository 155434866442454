export const firebaseConfig = {
  apiKey: "AIzaSyDh2T6VVTSWkmJ72YfHW-_eo3Na9CH2vw0",
  authDomain: "lcfh-51b81.firebaseapp.com",
  databaseURL: "https://lcfh-51b81-default-rtdb.firebaseio.com",
  projectId: "lcfh-51b81",
  storageBucket: "lcfh-51b81.appspot.com",
  messagingSenderId: "40854938701",
  appId: "1:40854938701:web:03ac38f68510896721a804",
  measurementId: "G-506YPHH5VH",
};

export const appCheckKey = "6LfbkuYaAAAAAKJnF45CGBk0eDVQbp69Ln3oKLaw";
