import React from "react";
import { Box, Text } from "@chakra-ui/react";
import logo from "../logo.svg";
import SplashSvg from "../splash.svg";
import "../App.css";

import Hero from "../components/Hero";
import { CompanyName } from "../components/Logo";

const DarkHero = (props) => {
  return (
    <header className="App-header">
      <Text
        bgGradient="linear(to-l, #7928CA,#FF0080)"
        bgClip="text"
        fontSize="6xl"
        fontWeight="extrabold"
      >
        Welcome to <CompanyName />
      </Text>
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.tsx</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </header>
  );
};

export function Home() {
  return (
    <Box className="App">
      <Hero
        title="Overclock your hardware recruiting"
        subtitle="We enable your business to efficiently interview, identify, and hire excellent hardware developers and engineers."
        image={SplashSvg}
        ctaText="Request a free demo"
        ctaLink="/demo"
      />
    </Box>
  );
}
