import React from "react";

import {
  Box,
  Text,
  Link as ChakraLink,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import { Link as RouterLink, Redirect } from "react-router-dom";

export function Link({ children, to }) {
  return (
    <ChakraLink
      marginStart="1"
      as={RouterLink}
      to={to}
      color={useColorModeValue("teal.500", "teal.200")}
      display={{ base: "block", sm: "revert" }}
    >
      {children}
    </ChakraLink>
  );
}
